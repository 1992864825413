import { Map } from 'immutable';
import actionTypes from './types';
import { CALL_TYPE_AUDIO_VIDEO, CHANNELS, WIDGET_MODE_EMBED } from '../../../constants';

const initialState = Map({
    widgetReady: false,
    wasClose: false,
    wasHide: false,
    showChat: false,
    haveChat: true,
    hideWidget: false,
    record: false,
    disabledInput: false,
    fullScreenMode: false,
    selectedChannel: '',
    localUser: Map({}),
    isLoading: false,
    call: Map({}),
    view: CHANNELS,
    widgetCallType: CALL_TYPE_AUDIO_VIDEO,
    canVideo: true,
    mode: WIDGET_MODE_EMBED,
    language: 'en',
    virtualBackground: { enable: false, type: 'blur' },
    callTools: {
        chat: true,
        screen_sharing: true,
        file_upload: true,
        snapshot: true,
        live_edit: true,
        live_pointer: true,
        whiteboard: {
            wb_add_file: true,
            wb_cursor: true,
            wb_text: true,
            wb_shape: true,
            wb_pen: true,
            wb_eraser: true
        }
    }
});

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.TOGGLE_CHAT: {
            return state.update('showChat', showChat => !showChat);
        }
        case actionTypes.TOGGLE_FULL_SCREEN: {
            return state.update('fullScreenMode', fullScreenMode => !fullScreenMode);
        }
        case actionTypes.SET_FULL_SCREEN_MODE: {
            return state.update('fullScreenMode', () => action.active);
        }
        case actionTypes.SET_CHAT_OPEN: {
            return state.update('showChat', () => true);
        }
        case actionTypes.SET_CHAT_CLOSE: {
            return state.update('showChat', () => false);
        }
        case actionTypes.HIDE_CHAT: {
            return state.update('hideWidget', () => action.hide);
        }
        case actionTypes.SET_RECORD_VALUE: {
            return state.update('record', () => action.record);
        }
        case actionTypes.TOGGLE_INPUT_DISABLED: {
            return state.update('disabledInput', disabledInput => !disabledInput);
        }
        case actionTypes.SELECT_CHANNEL: {
            return state.update('selectedChannel', () => action.selectedChannel);
        }
        case actionTypes.SET_LOCAL_USER_INFO: {
            return state.update('localUser', () => action.localUserInfo);
        }
        case actionTypes.WIDGET_READY: {
            return state.update('widgetReady', () => action.widgetReady);
        }
        case actionTypes.CHAT_IS_LOADING: {
            return state.update('isLoading', () => action.isLoading);
        }
        case actionTypes.CHANGE_VIEW: {
            return state.update('view', () => action.view);
        }
        case actionTypes.UPDATE_CALL: {
            return state.update('call', () => action.call);
        }
        case actionTypes.SET_WIDGET_CALL_TYPE: {
            return state.update('widgetCallType', () => action.widgetCallType);
        }
        case actionTypes.SET_CAN_VIDEO: {
            return state.update('canVideo', () => action.canVideo);
        }
        case actionTypes.SET_WIDGET_MODE: {
            return state.update('mode', () => action.widgetMode);
        }
        case actionTypes.SET_LANGUAGE: {
            return state.update('language', () => action.language);
        }
        case actionTypes.HAVE_CHAT: {
            return state.update('haveChat', () => action.haveChat);
        }
        case actionTypes.WAS_CLOSE: {
            return state.update('wasClose', () => action.wasClose);
        }
        case actionTypes.WAS_HIDE: {
            return state.update('wasHide', () => action.wasHide);
        }
        case actionTypes.SET_CHAT_INIT: {
            const callTools = state.get('callTools');
            callTools.chat = action.chat;
            return state.update('callTools', () => callTools);
        }
        case actionTypes.SET_CALL_TOOLS: {
            return state.update('callTools', () => action.callTools);
        }
        case actionTypes.SET_VIRTUAL_BACKGROUND: {
            return state.update('virtualBackground', () => action.virtualBackground);
        }
        default:
            return state;
    }
}
