import { BrowserUtil } from '@bandyer/web-core-av';
import actions from './actions';
import {
    CHANNELS,
    CONVERSATION,
    MESSAGE_SENDER,
    MESSAGES_STATUS,
    BROWSER_NOT_SUPPORTED,
    WIDGET_ALREADY_INITIATED,
    GENERIC_CALL_ERROR,
    GUM_ERROR,
    ERROR
} from '../../../constants';
import BandyerService from '../../../services';
import { AddChatError, WidgetAlreadyInitiateError, ChatRemoveError } from '../../../helpers/errorHandler';
import {
    validateCallTypeConfig,
    validateModeConfig,
    validateLanguageConfig,
    validateToolsConfig
} from '../../../helpers/parseConfig';
import Logger from '../../../logger';
import { styleOperations } from '../../common/style/redux';
import store from '../../../store/store';

const {
    addUserMessage,
    toggleChat,
    toggleFullScreen,
    logout,
    setHideWidget,
    wasHide,
    chatInitHasError,
    chatIsLoading,
    setLocalUserInfo,
    setRecordValue,
    setWidgetReady,
    setChannel,
    setMessages,
    updateUnreadMessagePerChannel,
    changeView,
    addResponseMessage,
    toggleInputDisabled,
    fetchedSubscribedChannels,
    removeSubscribedChannel,
    channelIsTyping,
    updateChannelLastMessage,
    showWidget,
    selectCurrentChannel,
    closeWidget,
    setExtensionUrl,
    updateLastMessageReceived,
    updateLastMessageSent,
    setUnreadMessagesPerChannel,
    fetchedSubscribedChannelsNoResults,
    setWidgetCallType,
    setCanVideo,
    setWidgetMode,
    setLanguage,
    setUserStatusInChannel,
    removeChat,
    setHaveChat,
    wasClose,
    setChatInitialized,
    setVirtualBackgroundConfig,
    setTools
} = actions;

/** ****************** MESSAGE OPERATIONS ********************** */

const sendMessage = function(uniqueName, message) {
    return () => BandyerService.getInstance().services.chat.sendMessage(uniqueName, message);
};

/** ****************** USER ********************** */

function getUserStatus(userAlias) {
    return () => BandyerService.getInstance().getUserStatus(userAlias);
}

function getUsersStatusList() {
    return () => BandyerService.getInstance().getUsersStatusList();
}

/** ******** CHANNEL OPERATIONS ******************** */

const selectChannel = function selectChannel(uniqueName, who) {
    const state = store.getState();
    let isViewConversation = false;
    return (dispatch) => {
        dispatch(chatIsLoading(true));
        if (state.messages.has(uniqueName)) {
            dispatch(setChannel(uniqueName));
            dispatch(changeView(CONVERSATION));
            dispatch(chatIsLoading(false));
            isViewConversation = true;
        }
        if (!BandyerService.getInstance().services.chat) {
            throw new Error('Chat not found');
        }
        return BandyerService.getInstance()
            .services.chat.selectCurrentChannel(uniqueName, who)
            .then((selectedChannel) => {
                // dispatch(emptyMessages());
                // dispatch(setChannel(selectedChannel.uniqueName));
                if (selectedChannel.dialog) {
                    dispatch(setMessages(selectedChannel.dialog, selectedChannel.uniqueName));
                }
                if (isViewConversation === false) {
                    dispatch(setChannel(uniqueName));
                    dispatch(changeView(CONVERSATION));
                    dispatch(chatIsLoading(false));
                }
                dispatch(updateUnreadMessagePerChannel(selectedChannel.uniqueName, 0));
            })
            .catch((err) => {
                dispatch(chatIsLoading(false));
                Logger.warn(err);
                throw new Error('Chat not found');
            });
    };
};

function fetchMessages(uniqueName, anchor) {
    return (dispatch) => {
        // loader
        const timeout = new Promise(resolve => setTimeout(() => {
            resolve();
        }, 2000));
        const fetch = BandyerService.getInstance().services.chat.fetchMessage(uniqueName, anchor);

        return Promise.all([timeout, fetch])
            .then(async(returnArray) => {
                const messages = returnArray[1];
                dispatch(setMessages(messages, uniqueName));
            })
            .catch((err) => {
                // disable Loader
                Logger.warn(err);
                throw new Error('Unable to fetch messages');
            });
    };
}

function expandWidget() {
    return (dispatch) => {
        const state = store.getState();
        dispatch(wasClose(!state.behavior.get('showChat')));
        dispatch(showWidget());
    };
}

function hideWidget(hide) {
    return (dispatch) => {
        const state = store.getState();
        dispatch(wasHide(state.behavior.get('hideWidget')));
        dispatch(setHideWidget(hide));
    };
}

function addChannel(user, options) {
    return async(dispatch) => {
        dispatch(chatIsLoading(true));
        if (!BandyerService.getInstance().services.chat) {
            await BandyerService.getInstance().initChatService();
        }
        return BandyerService.getInstance()
            .services.chat.addChannel(user, options)
            .then(() => {
                dispatch(chatIsLoading(false));
                dispatch(expandWidget());
                dispatch(hideWidget(false));
                return Promise.resolve();
            })
            .catch((err) => {
                dispatch(chatIsLoading(false));
                return Promise.reject(new AddChatError(`Something went wrong with the addChat method: ${err.message}`));
            });
    };
}

function removeChannel(user) {
    return (dispatch) => {
        dispatch(chatIsLoading(true));
        if (!BandyerService.getInstance().services.chat) {
            return Promise.reject(new ChatRemoveError('Chat not found'));
        }
        return BandyerService.getInstance()
            .services.chat.removeChannel(user)
            .then((uniqueNameRemoved) => {
                Logger.debug('Channel removed : ', uniqueNameRemoved);
                dispatch(chatIsLoading(false));
                return Promise.resolve();
            })
            .catch((err) => {
                dispatch(chatIsLoading(false));
                return Promise.reject(
                    new ChatRemoveError(`Something went wrong with the removeChat method : ${err.message} `)
                );
            });
    };
}

function sendIsTypingEvent(uniqueName) {
    return () => BandyerService.getInstance().services.chat.isTyping(uniqueName);
}

/* Fn that return to the channels view and bring the selected channel to none */
function resetToChannelsView() {
    return (dispatch) => {
        dispatch(changeView(CHANNELS));
        dispatch(selectCurrentChannel(''));
    };
}

/* Fn that is called on call event, allow to return to the current conversation or back to channels if
  there isn't a selected one
  */

function resetToChannelView() {
    return (dispatch) => {
        const state = store.getState();
        if (state.behavior.get('view') === ERROR) {
            return;
        }
        const selectedChannel = state.behavior.get('selectedChannel');
        if (state.behavior.get('wasClose')) {
            dispatch(closeWidget());
        }
        if (state.behavior.get('wasHide')) {
            dispatch(hideWidget(true));
        }
        if (selectedChannel === '') {
            dispatch(changeView(CHANNELS));
        } else {
            dispatch(changeView(CONVERSATION));
        }
    };
}

function resetCurrentChannel() {
    BandyerService.getInstance().services.chat.resetCurrentChannel();
}

/** ***************************************************************************
 ************ LIFECYCLE OPERATIONS ********************************************
 ************************************************************************** */

const initialize = function initialize(config) {
    return async(dispatch) => {
        dispatch(chatIsLoading(true));
        try {
            if (config.hidden) {
                dispatch(hideWidget(true));
            }
            if (config.record) {
                dispatch(setRecordValue(true));
            }
            if (config.layout) {
                styleOperations.handleConfigLayout(dispatch, config.layout);
            }
            if (config.screenSharingExtensionURL) {
                dispatch(setExtensionUrl(config.screenSharingExtensionURL));
            }
            dispatch(setLanguage(validateLanguageConfig(config.language)));
            const checkBrowserCompatibility = await BrowserUtil.checkBrowserCompatibility(config.record);
            if (!checkBrowserCompatibility) {
                throw new Error(BROWSER_NOT_SUPPORTED);
            }
            BandyerService.initialize({
                userAlias: config.userAlias,
                appId: config.appId,
                environment: config.environment,
                screenSharingExtensionId: config.screenSharingExtensionId
            });
            const commCenter = await BandyerService.getInstance().initCommunicationCenter();

            if (config.tools) {
                dispatch(setTools(validateToolsConfig(config.tools)));
            } else {
                config.tools = { chat: config.chat };
                dispatch(setChatInitialized(config.chat));
            }

            if (config.tools.chat) {
                const initChatService = async(data) => {
                    if (BandyerService.getInstance() && !BandyerService.getInstance().services.chat) {
                        await BandyerService.getInstance().initChatService();
                    }
                    commCenter.off('user:authenticate_twilio_chat', initChatService);
                };
                commCenter.on('user:authenticate_twilio_chat', initChatService);
            }
            if (config.virtualBackground) {
                const virtualBackground = { enable: true, type: config.virtualBackground };
                dispatch(setVirtualBackgroundConfig(virtualBackground));
            }
            BandyerService.getInstance().initUserDetailsService(
                config.userDetailsProvider,
                config.userDetailsFormatter
            );
            const localUser = await BandyerService.getInstance().getUser(config.userAlias);
            dispatch(chatIsLoading(false));
            dispatch(setLocalUserInfo(localUser.baseUser));
            dispatch(setCanVideo(localUser.canVideo));
            dispatch(setWidgetCallType(validateCallTypeConfig(config.callType)));
            dispatch(setWidgetMode(validateModeConfig(config.mode)));
            dispatch(setWidgetReady(true));
            return BandyerService.getInstance();
        } catch (err) {
            dispatch(chatInitHasError(true));
            dispatch(chatIsLoading(false));
            Logger.warn(err);
            const nameError = err.name;
            switch (nameError) {
                case WidgetAlreadyInitiateError.name:
                    throw new WidgetAlreadyInitiateError(WIDGET_ALREADY_INITIATED);
                default:
                    throw err;
            }
        }
    };
};

const shutdown = function shutdown() {
    return async(dispatch) => {
        try {
            await BandyerService.shutdown();
            Logger.debug('[chatLogout] - logout successfull');
            dispatch(setWidgetReady(false));
            dispatch(logout());
        } catch (e) {
            Logger.warn('[chatLogout] - logout error', e);
        }
    };
};

/** ******** CALL OPERATIONS ******************** */

export default {
    addUserMessage,
    sendMessage,
    selectChannel,
    toggleFullScreen,
    toggleChat,
    logout,
    initialize,
    changeView,
    updateUnreadMessagePerChannel,
    shutdown,
    setWidgetReady,
    addChannel,
    removeChannel,
    sendIsTypingEvent,
    resetCurrentChannel,
    resetToChannelsView,
    resetToChannelView,
    addResponseMessage,
    toggleInputDisabled,
    fetchedSubscribedChannels,
    removeSubscribedChannel,
    channelIsTyping,
    updateChannelLastMessage,
    expandWidget,
    selectCurrentChannel,
    closeWidget,
    setExtensionUrl,
    updateLastMessageReceived,
    updateLastMessageSent,
    setUnreadMessagesPerChannel,
    hideWidget,
    fetchedSubscribedChannelsNoResults,
    getUserStatus,
    setWidgetCallType,
    setCanVideo,
    setChannel,
    getUsersStatusList,
    setLanguage,
    setUserStatusInChannel,
    fetchMessages,
    removeChat,
    setHaveChat
};
