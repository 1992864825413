import { constants } from 'bandyersdkcommon';
import { Map } from 'immutable';
import {
    DIAL_IN,
    DIAL_OUT,
    GENERIC_CALL_ERROR,
    GUM_ERROR,
    MULTIPLE_SOCKET_ERROR,
    USERS_BUSY_ERROR,
    INVALID_PERMISSION_CAN_VIDEO,
    INITIATOR_NOT_AVAILABLE,
    ANSWER_CALL_ERROR,
    ACTION_ALREADY_TAKEN,
    PUBLISH_STREAM_NOT_VALID,
    SOCKET_CALL_ERROR,
    JOIN_CALL_REQUEST_FAILED,
    JOIN_CALL_INVALID_URL,
    CALL,
    JOIN_CALL_INVALID_MTM,
    CHANNELS,
    CONVERSATION,
    ERROR
} from '../../../../../../../constants';
import { buildParticipantFromChannelUniqueName, buildUserInfoFromImmutable } from '../../../../../../../helpers/utils';
import Logger from '../../../../../../../logger';
import BandyerServices from '../../../../../../../services';
import widgetOperations from '../../../../../redux/operations';
import errorOperations from '../../../../../../errors/redux/operations';
import actions from './actions';
import store from '../../../../../../../store/store';

const {
    updateCall,
    resetVideo,
    resetAudio,
    publishScreen,
    publishWebcam,
    setFullScreenMode,
    showExtensionAlert,
    toggleAudio,
    toggleVideo,
    changeMainVideo,
    setVideoHasAudioTrack,
    setVideoHasVideoTrack,
    enumerateDevices,
    selectAudioDevice,
    selectVideoDevice,
    setCameraPermissionDenied,
    setMicrophonePermissionDenied,
    setMediaStream,
    setStreamEndedError,
    setStreamFailedError,
    setReconnectingCall,
    setError,
    setVerification,
    setRemoteVideoMuted,
    addFile,
    setFileUploaded,
    updateDownloadFile,
    setFilesDownload,
    setFilesUpload,
    removeFileUploaded,
    updateRecordingInfo,
    updateRecordingInRecordingInfo,
    setIsAdmin,
    setVirtualBackgrounds,
    showRequestPlayPermission,
    setFailedMediaElements
} = actions;

const _hangUpFromCreateCall = async(dispatch, callAlias) => {
    const callStatus = BandyerServices.getInstance().services.call.getCallStatus(callAlias);
    try {
        if (callStatus === 'ended') {
            throw new Error('HANG_UP_ON_ENDED_CALL');
        } else if (callStatus === 'dialing') {
            await BandyerServices.getInstance().services.call.hangUp(callAlias);
        } else {
            await BandyerServices.getInstance().services.call.disconnectCall(callAlias);
        }
    } catch (e) {
        throw e;
    } finally {
        dispatch(widgetOperations.resetToChannelView());
        dispatch(updateCall({}));
        dispatch(setFullScreenMode(false));
        dispatch(publishScreen(false));
        dispatch(resetAudio());
        dispatch(resetVideo());
    }
};

function createCall(uniqueName, options = {}) {
    return async(dispatch) => {
        try {
            const { behavior } = store.getState();
            if (!behavior.get('call').isEmpty()) {
                throw new Error('ANOTHER_CALL_IN_PROGRESS');
            }
            if (behavior.get('view') === ERROR) {
                dispatch(widgetOperations.changeView(CHANNELS));
            }
            const call = await BandyerServices.getInstance().services.call.startCall(
                buildParticipantFromChannelUniqueName(uniqueName),
                options
            );
            Logger.debug('[createCall] - call: ', call);
            dispatch(updateCall(call));
            dispatch(widgetOperations.changeView(DIAL_OUT));
            dispatch(widgetOperations.hideWidget(false)); // shows the widget if hidden
            dispatch(widgetOperations.expandWidget()); // open the widget if it closed
            const callParticipants = [];
            call.callParticipants.forEach((p) => {
                callParticipants.push(p.user.userAlias);
            });
            return {
                callAlias: call.callAlias,
                callDirection: call.callDirection,
                callParticipants,
                get callStatus() {
                    return BandyerServices.getInstance().services.call.getCallStatus(call.callAlias);
                },
                hangUp: () => _hangUpFromCreateCall(dispatch, call.callAlias),
                callOptions: {
                    record: behavior.get('record'),
                    creationDate: new Date().toISOString(),
                    callType: call.callType,
                    live: true
                }
            };
        } catch (errMotivation) {
            Logger.warn('[createCall] - Error: ', errMotivation);
            dispatch(widgetOperations.hideWidget(false)); // shows the widget if hidden
            dispatch(widgetOperations.expandWidget()); // open the widget if it closed
            // todo gestire errore in fase di creazione call
            if (errMotivation.message === 'ANOTHER_CALL_IN_PROGRESS') {
                // to prenvent multiple call and different error handling
                throw errMotivation.message;
            }
            switch (errMotivation) {
                case GENERIC_CALL_ERROR:
                    dispatch(errorOperations.changeToViewError(GENERIC_CALL_ERROR));
                    break;
                case INITIATOR_NOT_AVAILABLE:
                    dispatch(errorOperations.changeToViewError(INITIATOR_NOT_AVAILABLE));
                    break;
                case USERS_BUSY_ERROR:
                    dispatch(errorOperations.changeToViewError(USERS_BUSY_ERROR));
                    break;
                case INVALID_PERMISSION_CAN_VIDEO:
                    dispatch(errorOperations.changeToViewError(INVALID_PERMISSION_CAN_VIDEO));
                    break;
                // case CREATE_CALL_FAILED:
                default:
                    dispatch(errorOperations.changeToViewError(GENERIC_CALL_ERROR));
            }
            throw errMotivation;
        }
    };
}

function joinCallURL(url) {
    return async(dispatch) => {
        try {
            const { behavior } = store.getState();
            if (!behavior.get('call').isEmpty()) {
                throw new Error('ANOTHER_CALL_IN_PROGRESS');
            }
            if (behavior.get('view') === ERROR) {
                dispatch(widgetOperations.changeView(CHANNELS));
            }
            const call = await BandyerServices.getInstance().services.call.joinCallURL(url);
            Logger.debug('[joinCallURL] - call: ', call);
            dispatch(updateCall(call));
            if (call.callStatus === 'dialing') {
                dispatch(widgetOperations.changeView(DIAL_OUT));
            } else {
                dispatch(widgetOperations.changeView(CALL));
            }
            dispatch(widgetOperations.hideWidget(false)); // shows the widget if hidden
            dispatch(widgetOperations.expandWidget()); // open the widget if it closed
            const callParticipants = [];
            call.callParticipants.forEach((p) => {
                callParticipants.push(p.user.userAlias);
            });
            return {
                callAlias: call.callAlias,
                callDirection: call.callDirection,
                callParticipants,
                get callStatus() {
                    return BandyerServices.getInstance().services.call.getCallStatus(call.callAlias);
                },
                hangUp: () => _hangUpFromCreateCall(dispatch, call.callAlias),
                callOptions: {
                    record: behavior.get('record'),
                    creationDate: new Date().toISOString(),
                    callType: call.callType,
                    live: BandyerServices.getInstance().services.call.getCallOptions(call.callAlias).live
                }
            };
        } catch (errMotivation) {
            Logger.warn('[joinCallURL] - Error: ', errMotivation);
            dispatch(widgetOperations.hideWidget(false)); // shows the widget if hidden
            dispatch(widgetOperations.expandWidget()); // open the widget if it closed
            // todo gestire errore in fase di creazione call
            if (errMotivation.message === 'ANOTHER_CALL_IN_PROGRESS') {
                // to prenvent multiple call and different error handling
                throw errMotivation.message;
            }
            switch (errMotivation) {
                case JOIN_CALL_REQUEST_FAILED:
                    dispatch(errorOperations.changeToViewError(JOIN_CALL_REQUEST_FAILED));
                    throw errMotivation.toUpperCase();
                case JOIN_CALL_INVALID_URL:
                    dispatch(errorOperations.changeToViewError(JOIN_CALL_INVALID_URL));
                    throw errMotivation.toUpperCase();
                case JOIN_CALL_INVALID_MTM:
                    dispatch(errorOperations.changeToViewError(JOIN_CALL_INVALID_MTM));
                    throw errMotivation.toUpperCase();
                default:
                    dispatch(errorOperations.changeToViewError(GENERIC_CALL_ERROR));
                    throw errMotivation.toUpperCase();
            }
        }
    };
}

function handleIncomingCall(call) {
    return (dispatch) => {
        dispatch(widgetOperations.hideWidget(false));
        dispatch(updateCall(call));
        dispatch(widgetOperations.changeView(DIAL_IN));
        dispatch(widgetOperations.expandWidget());
    };
}

function answerTheCall(call) {
    return async(dispatch) => {
        try {
            const currentcall = await BandyerServices.getInstance().services.call.answer(call.get('callAlias'));
            dispatch(updateCall(currentcall));
        } catch (errMotivation) {
            Logger.warn('[Err in answerTheCall] - Err:', errMotivation);
            switch (errMotivation) {
                case ANSWER_CALL_ERROR:
                    dispatch(errorOperations.changeToViewError(ANSWER_CALL_ERROR));
                    break;
                case ACTION_ALREADY_TAKEN:
                    dispatch(errorOperations.changeToViewError(ACTION_ALREADY_TAKEN));
                    break;
                case GENERIC_CALL_ERROR:
                default:
                    BandyerServices.getInstance().services.call.disconnectCall(call.get('callAlias'));
                    dispatch(errorOperations.changeToViewError(GENERIC_CALL_ERROR));
                    break;
            }
        }
    };
}

function declineTheCall(call) {
    return async(dispatch) => {
        try {
            await BandyerServices.getInstance().services.call.decline(call.get('callAlias'));
            dispatch(widgetOperations.resetToChannelView());
            dispatch(updateCall({}));
            dispatch(setFullScreenMode(false));
            dispatch(resetAudio());
            dispatch(resetVideo());
        } catch (errMotivation) {
            Logger.warn('[Err in declineTheCall] - Err:', errMotivation);
            switch (errMotivation) {
                case ACTION_ALREADY_TAKEN:
                    break;
                case GENERIC_CALL_ERROR:
                default:
                    BandyerServices.getInstance().services.call.disconnectCall(call.get('callAlias'));
                    dispatch(errorOperations.changeToViewError(GENERIC_CALL_ERROR));
                    break;
            }
        }
    };
}

function hangUpTheCall(call) {
    return async(dispatch) => {
        try {
            await BandyerServices.getInstance().services.call.hangUp(call.get('callAlias'));
        } catch (e) {
            Logger.warn('[Err in hangUpTheCall] - Err:', e);
        }
        dispatch(widgetOperations.resetToChannelView());
        dispatch(updateCall({}));
        dispatch(setFullScreenMode(false));
        dispatch(publishScreen(false));
        dispatch(resetAudio());
        dispatch(resetVideo());
    };
}

function closePlugin(call) {
    if (call.get('callDirection') === 'call_direction_incoming') {
        return declineTheCall(call);
    }
    return hangUpTheCall(call);
}

function handleDeclinedCall() {
    return (dispatch) => {
        dispatch(widgetOperations.resetToChannelView());
        dispatch(updateCall({}));
        dispatch(setFullScreenMode(false));
        dispatch(publishScreen(false));
        dispatch(resetAudio());
        dispatch(resetVideo());
    };
}

function closeError() {
    return (dispatch) => {
        dispatch(widgetOperations.changeView(CHANNELS));
        dispatch(updateCall({}));
        dispatch(setFullScreenMode(false));
        dispatch(publishScreen(false));
        dispatch(resetAudio());
        dispatch(resetVideo());
    };
}

function handleStoppedCall(call) {
    return async(dispatch) => {
        await BandyerServices.getInstance().services.call.disconnectCall(call.callAlias);
        dispatch(widgetOperations.resetToChannelView());
        dispatch(updateCall({}));
        dispatch(setFullScreenMode(false));
        dispatch(publishScreen(false));
        dispatch(resetAudio());
        dispatch(resetVideo());
    };
}

function errorGum() {
    return (dispatch) => {
        dispatch(errorOperations.changeToViewError(GUM_ERROR));
        dispatch(updateCall({}));
        dispatch(setFullScreenMode(false));
    };
}

function multipleSocketError() {
    return (dispatch) => {
        dispatch(errorOperations.changeToViewError(MULTIPLE_SOCKET_ERROR));
        dispatch(updateCall({}));
        dispatch(setFullScreenMode(false));
    };
}

function errorCallSocket() {
    return (dispatch) => {
        dispatch(errorOperations.changeToViewError(SOCKET_CALL_ERROR));
        dispatch(updateCall({}));
        dispatch(setFullScreenMode(false));
    };
}

function focusWindowCall() {
    return () => BandyerServices.getInstance().services.call.focusWindowCall();
}

function closeWindowCall() {
    return (dispatch) => {
        BandyerServices.getInstance().services.call.closeWindowCall();
        dispatch(widgetOperations.resetToChannelView());
        dispatch(updateCall({}));
        dispatch(setFullScreenMode(false));
        dispatch(publishScreen(false));
        dispatch(resetAudio());
        dispatch(resetVideo());
    };
}

/** **********************************************************************************************************
 ************************************************ ROOM BANDYER KIT ********************************************
 *********************************************************************************************************** */

function publishWebcamStream(call, user, record) {
    return async(dispatch) => {
        try {
            dispatch(publishWebcam(false));
            await BandyerServices.getInstance().services.call.publishStream(
                call.get('callAlias'),
                record,
                buildUserInfoFromImmutable(user)
            );
            dispatch(publishWebcam(true));
        } catch (err) {
            Logger.warn('[Err in publishWebcamStream] - Err:', err);
            switch (err) {
                case PUBLISH_STREAM_NOT_VALID:
                    // Forse non va qua
                    // the stream is not valid but the user stays connected
                    break;
                case GUM_ERROR:
                    dispatch(errorOperations.changeToViewError(GUM_ERROR));
                    break;
                default:
                    BandyerServices.getInstance().services.call.disconnectCall(call.get('callAlias'));
                    dispatch(errorOperations.changeToViewError(GENERIC_CALL_ERROR));
            }
        }
    };
}

/**
 * This function handle two cases: if the local webcam has audio stream, it toggles the audio.
 * @param {*} call object
 */
function togglePublisherAudio(call) {
    return async(dispatch) => {
        if (BandyerServices.getInstance().services.call.hasAudio()) {
            const result = BandyerServices.getInstance().services.call.toggleAudio(call.get('callAlias'));
            dispatch(toggleAudio(!result));
        }
        /*
        NO UNPUBLISH E REPUBLISH, NOW OPEN THE GEAR
        try {
            await BandyerServices.getInstance().services.call.unpublishWebcam();
        } catch (err) {
            Logger.warn('[togglePublisherAudio] - unpublishWebcam', err);
            if (err.message !== UNPUBLISH_STREAM_NO_STREAM) {
                Logger.debug('[togglePublisherAudio] - UnpublishStreamError', err);
                throw err;
            }
        }
        try {
            await BandyerServices.getInstance().services.call.publishWebCam(call.get('callAlias'), { audio: true, video: false });
        } catch (err) {
            Logger.warn('[togglePublisherAudio] - publishWebCam', err);
            switch (err) {
                case PUBLISH_STREAM_NOT_VALID:
                    throw NO_AVAILABLE_MICROPHONE;
                default:
                    dispatch(errorOperations.changeToViewError(GENERIC_CALL_ERROR));
            }
        }
        */
    };
}

function togglePublisherVideo(call) {
    return async(dispatch) => {
        const result = BandyerServices.getInstance().services.call.toggleVideo(call.get('callAlias'));
        dispatch(toggleVideo(!result));
    };
}

function publisherHasAudio() {
    return async(dispatch) => {
        const result = BandyerServices.getInstance().services.call.hasAudio();
        dispatch(setVideoHasAudioTrack(result));
    };
}

function publisherHasVideo() {
    return async(dispatch) => {
        const result = BandyerServices.getInstance().services.call.hasVideo();
        dispatch(setVideoHasVideoTrack(result));
    };
}

function requestPermissionToUpgradeVideo(call) {
    return async() => BandyerServices.getInstance().services.call.requestPermissionToUpgradeVideo(call);
}

function upgradeToPublisherWebcam(callAlias) {
    return async(dispatch) => {
        try {
            await BandyerServices.getInstance().services.call.unpublishWebcam();
            await BandyerServices.getInstance().services.call.publishWebCam(callAlias, { audio: true, video: true });
            dispatch(resetAudio());
            dispatch(resetVideo());
        } catch (err) {
            // todo da migliorare
            switch (err) {
                case GUM_ERROR:
                    dispatch(errorOperations.changeToViewError(GUM_ERROR));
                    break;
                case PUBLISH_STREAM_NOT_VALID:
                    break;
                default:
                    dispatch(errorOperations.changeToViewError(GENERIC_CALL_ERROR));
            }
        }
    };
}

function disconnectTheCall(call) {
    return async(dispatch) => {
        await BandyerServices.getInstance().services.call.disconnectCall(call.get('callAlias'));
        dispatch(widgetOperations.resetToChannelView());
        dispatch(updateCall({}));
        dispatch(setFullScreenMode(false));
        dispatch(publishScreen(false));
        dispatch(resetAudio());
        dispatch(resetVideo());
    };
}

function addFileToDownload(file) {
    return async(dispatch) => {
        const name = file.url.split('/').pop();
        const fileMap = Map({ url: file.url, name, progress: 0, withCredentials: file.withCredentials });
        dispatch(addFile(fileMap));
    };
}

function resetFile() {
    return async(dispatch) => {
        dispatch(setFilesDownload(Map([])));
        dispatch(setFilesUpload(Map([])));
    };
}

function publishScreenStream(call, fps) {
    return async(dispatch) => {
        try {
            await BandyerServices.getInstance().services.call.publishScreen(call.get('callAlias'), fps);
            dispatch(publishScreen(true));
        } catch (e) {
            const errorMessage = e.message;
            if (errorMessage) {
                switch (errorMessage) {
                    case constants.SDK_SCREENSHARE_EXTENSION_NOT_INSTALLED:
                        dispatch(showExtensionAlert(true));
                        break;
                    case constants.SDK_REPLACE_TRACK_NOT_SUPPORTED:
                        break;
                    default:
                    // dispatch(showExtensionAlert(true));
                }
            }
        }
    };
}

function unpublishScreenStream(call) {
    return async(dispatch) => {
        try {
            await BandyerServices.getInstance().services.call.unpublishScreen(call.get('callAlias'));
            dispatch(publishScreen(false));
        } catch (e) {
            Logger.warn('Error in unpublishScreenStream', e);
        }
    };
}

function getEnumerateDevices(request = 'all') {
    return async(dispatch) => {
        let result = await BandyerServices.getInstance().services.call.enumerateDevices();
        switch (request) {
            case 'audio':
                if (result.every(device => (device.kind === 'audioinput' ? device.label === '' : true))) {
                    return navigator.mediaDevices
                        .getUserMedia({ audio: true })
                        .then(async(mediaStream) => {
                            BandyerServices.getInstance()
                                .services.call.enumerateDevices()
                                .then((devices) => {
                                    result = devices;
                                    dispatch(enumerateDevices(result));
                                    dispatch(setMicrophonePermissionDenied(false));
                                })
                                .finally(() => mediaStream.getTracks().forEach(t => t.stop()));
                        })
                        .catch(() => {
                            Logger.warn('getEnumerateDevices - setMicrophonePermissionDenied true');
                            dispatch(setMicrophonePermissionDenied(true));
                            dispatch(enumerateDevices(result));
                        });
                }
                break;
            case 'video':
                if (result.every(device => (device.kind === 'videoinput' ? device.label === '' : true))) {
                    return navigator.mediaDevices
                        .getUserMedia({ video: true })
                        .then(async(mediaStream) => {
                            BandyerServices.getInstance()
                                .services.call.enumerateDevices()
                                .then((devices) => {
                                    result = devices;
                                    dispatch(enumerateDevices(result));
                                    dispatch(setCameraPermissionDenied(false));
                                })
                                .finally(() => mediaStream.getTracks().forEach(t => t.stop()));
                        })
                        .catch(() => {
                            dispatch(setCameraPermissionDenied(true));
                            dispatch(enumerateDevices(result));
                        });
                }
                break;
            case 'all':
                if (
                    result.every(device => (device.kind === 'videoinput' || device.kind === 'audioinput' ? device.label === '' : true))
                ) {
                    return navigator.mediaDevices
                        .getUserMedia({ audio: true, video: true })
                        .then(async(mediaStream) => {
                            result = await BandyerServices.getInstance()
                                .services.call.enumerateDevices()
                                .then((devices) => {
                                    result = devices;
                                    dispatch(enumerateDevices(result));
                                    dispatch(setCameraPermissionDenied(false));
                                    dispatch(setMicrophonePermissionDenied(false));
                                })
                                .finally(() => mediaStream.getTracks().forEach(t => t.stop()));
                        })
                        .catch(() => {
                            dispatch(setCameraPermissionDenied(true));
                            dispatch(enumerateDevices(result));
                        });
                }
                break;
            default:
                dispatch(enumerateDevices(result));
                return true;
        }

        dispatch(enumerateDevices(result));
        return true;
    };
}

function changeDeviceSource(callAlias, audioDevice, videoDevice) {
    return async(dispatch) => {
        try {
            await BandyerServices.getInstance().services.call.unpublishWebcam();
            const config = {
                audio: { deviceId: { exact: audioDevice } },
                video: { deviceId: { exact: videoDevice } }
            };
            if (audioDevice === 'none') {
                config.audio = false;
                dispatch(toggleAudio(true));
            } else {
                dispatch(resetAudio());
            }
            if (videoDevice === 'none') {
                config.video = false;
                dispatch(toggleVideo(true));
            } else {
                dispatch(resetVideo());
            }
            /* if (audioDevice === 'none' && videoDevice === 'video') {
                config.data = true;
            } */
            await BandyerServices.getInstance().services.call.publishWebCam(callAlias, config);
        } catch (err) {
            // todo da migliorare
            switch (err) {
                case PUBLISH_STREAM_NOT_VALID:
                    break;
                default:
                    dispatch(errorOperations.changeToViewError(GENERIC_CALL_ERROR));
            }
        }
    };
}

function handleManualRecording(record) {
    return async(dispatch) => {
        try {
            let handledRecord = await BandyerServices.getInstance().services.call.handleManualRecording(record);
            // if we start a recording we expect true and make the isRecording flag to true, if we stop the recording, we expect true but we update with false
            handledRecord = record ? handledRecord : !handledRecord;
            return dispatch(updateRecordingInRecordingInfo(handledRecord));
        } catch (err) {
            // DO NOTHING ??
        }
    };
}

export default {
    createCall,
    updateCall,
    handleDeclinedCall,
    handleIncomingCall,
    handleStoppedCall,
    errorGum,
    errorCallSocket,
    hangUpTheCall,
    answerTheCall,
    declineTheCall,
    showExtensionAlert,
    toggleAudio,
    toggleVideo,
    changeMainVideo,
    publishWebcamStream,
    unpublishScreenStream,
    publishScreenStream,
    disconnectTheCall,
    togglePublisherAudio,
    togglePublisherVideo,
    closeWindowCall,
    publishWebcam,
    publishScreen,
    focusWindowCall,
    upgradeToPublisherWebcam,
    requestPermissionToUpgradeVideo,
    publisherHasAudio,
    publisherHasVideo,
    getEnumerateDevices,
    selectAudioDevice,
    selectVideoDevice,
    changeDeviceSource,
    setCameraPermissionDenied,
    setMicrophonePermissionDenied,
    setMediaStream,
    setStreamEndedError,
    setStreamFailedError,
    multipleSocketError,
    joinCallURL,
    setReconnectingCall,
    setError,
    setVerification,
    setRemoteVideoMuted,
    addFileToDownload,
    setFileUploaded,
    updateDownloadFile,
    resetFile,
    removeFileUploaded,
    closePlugin,
    closeError,
    updateRecordingInfo,
    handleManualRecording,
    updateRecordingInRecordingInfo,
    setIsAdmin,
    setVirtualBackgrounds,
    showRequestPlayPermission,
    setFailedMediaElements
};
