import { constants, BandyerEnum } from 'bandyersdkcommon';
import { Room, Publisher, BrowserPlugin, BaseUser, setLogLevel as coreAVsetLogLevel } from '@bandyer/web-core-av';
import * as dispatcher from '../../store/actions/dispatcher';
import Logger from '../../logger';
import AttachStream from './attachStream';
import CallWindow from './callWindow';
import { buildDeclineReason, buildHangupReason } from '../../helpers/utils';
import {
    SUBSCRIBER_SCREEN,
    SUBSCRIBER_WEBCAM,
    LOCAL_WEBCAM,
    LOCAL_SS,
    REMOTE_WEBCAM,
    REMOTE_SS,
    GUM_ERROR,
    GENERIC_CALL_ERROR,
    USERS_BUSY_ERROR,
    INVALID_PERMISSION_CAN_VIDEO,
    INITIATOR_NOT_AVAILABLE,
    ANSWER_CALL_ERROR,
    ACTION_ALREADY_TAKEN,
    WIDGET_MODE_WINDOW,
    CALL_WINDOW_HANGUP_ACTION,
    PUBLISH_STREAM_NOT_VALID,
    CALL_TYPE_AUDIO_UPGRADABLE,
    JOIN_CALL_INVALID_URL,
    JOIN_CALL_REQUEST_FAILED,
    JOIN_CALL_INVALID_MTM,
    E_BANDYER_CALL_ROOM_RECONNECTING,
    E_BANDYER_CALL_ROOM_RECONNECTED,
    PUBLISHER_WEBCAM
} from '../../constants';
import store from '../../store/store';

const events = require('events');

class Call extends events {
    constructor(bandyerCommunicationCenter, screenSharingExtensionId) {
        super();
        this._L = Logger.scope('Services - Call');
        this._maxParticipants = 2;
        this._bandyerCommunicationCenter = bandyerCommunicationCenter;
        this._bandyerAVCore = {
            publisher: null,
            room: null
        };
        this._screenSharingExtensionId = screenSharingExtensionId;
        this._attachStream = new AttachStream();
        this._callWindow = new CallWindow(this._bandyerCommunicationCenter);
        if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'demo') {
            window.bandyerAVCORE = this._bandyerAVCore;
            coreAVsetLogLevel(1);
        }
        this._callWindow.on(CALL_WINDOW_HANGUP_ACTION, (callObj) => {
            const { onGoingCall } = this._bandyerCommunicationCenter;
            if (onGoingCall && onGoingCall.callAlias && onGoingCall.callStatus !== BandyerEnum.CallStatus.ENDED) {
                this.hangUp(this._bandyerCommunicationCenter.onGoingCall.callAlias);
            }
            if (callObj.call) {
                this.emit('call_ended', this._createObjToEmit(callObj));
            }
            dispatcher.resetCall();
        });
        this._callWindow.on('call_started_window', (callObj) => {
            this.emit('call_started', this._createObjToEmit(callObj));
        });
    }

    static initialize(bandyerCommunicationCenter, screenSharingExtensionId = null) {
        return new Call(bandyerCommunicationCenter, screenSharingExtensionId);
    }

    /**
     * This function register the events of the communication center such as incoming call
     */
    registerCommunicationCenterEvents() {
        /**
         * This is the incoming call event. The function check if the userAlias initiator is different from the
         * logged user in communicationcenter, if the call is one to one
         */
        this._bandyerCommunicationCenter.on(constants.SDK_EVENTS_CALL_INCOMING, (call) => {
            if (
                call.callInitiator
                && call.callInitiator.user
                && call.callInitiator.user.userAlias
                && this._bandyerCommunicationCenter.userAlias
                && call.callInitiator.user.userAlias !== this._bandyerCommunicationCenter.userAlias
                && call.callParticipants.length <= this._maxParticipants
            ) {
                this._registerCallEvents(call);
                dispatcher.handleIncomingCall(Call._buildCallData(call));
            }
        });

        this._bandyerCommunicationCenter.on('call:user:verified', (verification) => {
            if (verification.userId !== undefined) {
                dispatcher.setVerification(verification);
            }
        });
        this._bandyerCommunicationCenter.on(constants.SOCKET_MULTIPLE_SOCKET, () => {
            dispatcher.handleMultipleSocket();
        });

        this._bandyerCommunicationCenter.on('upload:new', (file) => {
            if (file.url) {
                dispatcher.addFileToDownload(file);
            }
        });

        this._bandyerCommunicationCenter.on('room:recording:start', (data) => {
            dispatcher.updateRecordingInRecordingInfo(true);
            this._bandyerAVCore.room.handleManualRecording(true);
        });

        this._bandyerCommunicationCenter.on('room:recording:stop', (data) => {
            dispatcher.updateRecordingInRecordingInfo(false);
            this._bandyerAVCore.room.handleManualRecording(false);
        });
    }

    async joinCallURL(url) {
        try {
            const callCreated = await this._bandyerCommunicationCenter.joinCall(url);
            this._registerCallEvents(callCreated);
            return Call._buildCallData(callCreated);
        } catch (e) {
            this._L.error('[joinCallURL] - Error: ', e);
            switch (e.message) {
                case 'join_call_request_failed':
                    throw JOIN_CALL_REQUEST_FAILED;
                case 'join_call_invalid_url':
                    throw JOIN_CALL_INVALID_URL;
                case JOIN_CALL_INVALID_MTM:
                    throw JOIN_CALL_INVALID_MTM;
                default:
                    throw GENERIC_CALL_ERROR;
            }
        }
    }

    /**
     * This function start a call using the communication center api. It calls the call method of Communication center.
     * After that, it calls the register callEvents function to listen the event of call created.
     * @param callee
     * @param options
     * @returns {Promise<{callInfo: Object, callDirection: string, callStatus: any, callAlias: string | string, callInitiator: ParticipantObjectClient | string, callParticipants: ParticipantObjectClient[] | UserObjectClient[]}>}
     * @private
     */
    async _startCall(callee, options) {
        const result = dispatcher.getWidgetMode();
        try {
            let callCreated = null;
            if (result === WIDGET_MODE_WINDOW) {
                this._callWindow.startCallWindow();
                // do not autoconnect to call serer
                callCreated = await this._bandyerCommunicationCenter.call(callee, options, false);
            } else {
                callCreated = await this._bandyerCommunicationCenter.call(callee, options);
            }
            this._registerCallEvents(callCreated);
            return Call._buildCallData(callCreated);
        } catch (e) {
            this._L.trace(`[_startCall] ErrorName ${e.name}, message: `, e.message);
            if (result === WIDGET_MODE_WINDOW) {
                this.closeWindowCall();
            }
            switch (e.name) {
                case 'InvalidInputError':
                    // the message can be invalid_users
                    // faccio un throw mandando l'utente alla pagine di errore con scritto
                    // Non è stasto possibile iniziare la chiamata
                    throw GENERIC_CALL_ERROR;
                case 'CreateCallError':
                    // the message can be create_call_request_failed
                    // faccio un throw mandando l'utente alla pagine di errore con scritto
                    // La chiamata non è iniziata, l'utente è occupato?
                    switch (e.message) {
                        case 'create_call_initiator_not_available':
                            throw INITIATOR_NOT_AVAILABLE;
                        case 'create_call_request_failed':
                            throw GENERIC_CALL_ERROR;
                        case 'create_call_users_busy':
                            throw USERS_BUSY_ERROR;
                        case 'create_call_invalid_permission_for_audio_video':
                            throw INVALID_PERMISSION_CAN_VIDEO;
                        default:
                    }
                    break;
                case 'CommunicationCenterConnectionError':
                    // comm_center_not_connected
                    // faccio un throw mandando l'utente alla pagine di errore con scritto
                    // Per Non è stasto possibile iniziare la chiamata
                    throw GENERIC_CALL_ERROR;
                default:
                    throw e;
            }
            throw e;
        }
    }

    /**
     * Interface to handle the plugin forIE
     * @param callee
     * @param options
     * @returns {Promise<void>}
     */
    async startCall(callee = [], options = {}) {
        return BrowserPlugin.handlePluginForIE(
            this._startCall.bind(this, callee, options),
            dispatcher.changeViewToIEPlugin
        );
    }

    async _answer(callAlias) {
        const result = dispatcher.getWidgetMode();
        try {
            const currentCall = this._bandyerCommunicationCenter.getCall(callAlias);
            /* TODO: qui devo distinguere in base al mode del widget, lo devo fare nel reducer */
            if (result === WIDGET_MODE_WINDOW) {
                if (currentCall.callStatus === BandyerEnum.CallStatus.DIALING) {
                    this._callWindow.answerCallWindow(callAlias);
                    await currentCall.answer(false);
                } else {
                    this._callWindow.focusWindow();
                }
            } else {
                await currentCall.answer();
                dispatcher.changeViewToCall();
            }
            return Call._buildCallData(currentCall);
        } catch (e) {
            this._L.warn('[_answer] - Err:', e);
            this._L.warn('[_answer] - Err Name:', e.name);
            if (result === WIDGET_MODE_WINDOW) {
                this.closeWindowCall();
            }
            switch (e.name) {
                case 'AnswerCallError':
                    // the message can be invalid_users
                    // faccio un throw mandando l'utente alla pagine di errore con scritto
                    // Non è stato possibile iniziare la chiamata
                    // throw GENERIC_CALL_ERROR;
                    switch (e.message) {
                        case 'another_ongoing_call':
                        case 'no_ingoing_call':
                        case 'answer_call_request_failed':
                        case 'room_not_found':
                        case 'callee_not_available':
                            throw ANSWER_CALL_ERROR;
                        default:
                            throw GENERIC_CALL_ERROR;
                    }
                // in this case if the error is action already taken, I just ignore the error and continue execution
                case 'CallActionAlreadyTaken':
                    throw ACTION_ALREADY_TAKEN;
                case 'CallConnectionError':
                    switch (e.message) {
                        case 'call_server_connection_failed':
                        default:
                            throw GENERIC_CALL_ERROR;
                    }
                default:
                    throw GENERIC_CALL_ERROR;
            }
        }
    }

    async answer(callAlias) {
        this._L.debug('[s.answer]');
        return BrowserPlugin.handlePluginForIE(this._answer.bind(this, callAlias), dispatcher.changeViewToIEPlugin);
    }

    async decline(callAlias, reason) {
        BrowserPlugin.stopPluginRequest();
        return this._bandyerCommunicationCenter
            .getCall(callAlias)
            .decline(buildDeclineReason(reason))
            .catch((e) => {
                this._L.warn('[decline] - Err:', e);
                switch (e.name) {
                    case 'CallActionAlreadyTaken':
                        throw ACTION_ALREADY_TAKEN;
                    // in this case if the error is action already taken, I just ignore the error and continue execution
                    default:
                        throw GENERIC_CALL_ERROR;
                }
            });
    }

    async hangUp(callAlias, reason) {
        BrowserPlugin.stopPluginRequest();
        return this._bandyerCommunicationCenter
            .getCall(callAlias)
            .hangUp(buildHangupReason(reason))
            .catch((e) => {
                this._L.warn('[hangUp] - Err:', e);
                switch (e.name) {
                    case 'CallActionAlreadyTaken':
                        throw ACTION_ALREADY_TAKEN;
                    // in this case if the error is action already taken, I just ignore the error and continue execution
                    default:
                }
            });
    }

    async disconnectCall(callAlias) {
        this._L.debug('[disconnectCall] - callAlias: ', callAlias);
        // USER VERIFICATION ENDED
        dispatcher.setVerification(null);
        dispatcher.resetFile();
        this._bandyerCommunicationCenter.getCall(callAlias).disconnect();
        if (this._bandyerAVCore.room) {
            this._bandyerAVCore.room.disconnect();
        }
    }

    /**
     * The publishStream function handle the room connection in licode and initialization of the publisher object
     * @param {String} callAlias
     */
    async publishStream(callAlias = null, record = false) {
        this._L.debug('[s.publishStream]', callAlias);
        const { room } = this._bandyerAVCore;
        this._L.debug('[s.publishStream- room]', room);
        if (callAlias) {
            try {
                if (!room || !room.room || room.room.state === 0) {
                    const currentCall = this._bandyerCommunicationCenter.getCall(callAlias);
                    this._bandyerAVCore.room = Room.initialize(
                        currentCall.callToken.token,
                        currentCall.callOptions.record,
                        currentCall.callOptions.recordingType
                    );
                    const recording = currentCall.callOptions.record && currentCall.callOptions.recordingType === 'automatic';
                    dispatcher.updateRecordingInfo({ recording, recordingType: currentCall.callOptions.recordingType });
                    if (currentCall.callHost.isAdmin) {
                        dispatcher.setIsAdmin(true);
                    }
                    // populate virtualBackgrounds list
                    const data = {}; // future purpose
                    const timePromise = new Promise((resolve) => {
                        setTimeout(resolve, 1000, []);
                    });
                    const virtualBackgrounds = await Promise.race([
                        this._bandyerCommunicationCenter.getBackgroundList(data),
                        timePromise
                    ]);
                    dispatcher.setVirtualBackgrounds(virtualBackgrounds);
                    this._registerRoomEvents();
                    await this._bandyerAVCore.room.connectRoom();
                    const currentUser = await this._bandyerCommunicationCenter.getCurrentUser();
                    this._L.debug(
                        `[s.publishStream] - User stream to publish:${currentUser.baseUser.userAlias} with canVideo: ${currentUser.canVideo}`
                    );
                    this._bandyerAVCore.publisher = Publisher.initialize(
                        BaseUser.initialize(currentUser.baseUser),
                        currentUser.canVideo
                    );
                    if (virtualBackgrounds.length) {
                        this._bandyerAVCore.publisher.setVirtualBackground(virtualBackgrounds[0].imageUrl);
                    }
                } else {
                    // if there is an active room, get the remote stream and attach them
                    const streams = this._bandyerAVCore.room.getRemoteStreams();
                    streams.forEach((stream) => {
                        this._handleStreamSubscribed(stream);
                    });
                }
                const devices = await this.enumerateDevices();
                if (devices.every(device => (device.kind === 'audioinput' ? device.label === '' : true))) {
                    dispatcher.setMicrophonePermissionDenied(true);
                } else {
                    dispatcher.setMicrophonePermissionDenied(false);
                }
                if (devices.every(device => (device.kind === 'videoinput' ? device.label === '' : true))) {
                    dispatcher.setCameraPermissionDenied(true);
                } else {
                    dispatcher.setCameraPermissionDenied(false);
                }
            } catch (e) {
                this._L.warn('[publishStream] - Err', e);
                switch (e.name) {
                    case 'RoomConnectionError':
                        // the message can be room_connection_failed or room_config_token_not_valid
                        // faccio un throw mandando l'utente alla pagine di errore con scritto
                        // Per favore riprovare, errore generico.
                        throw GENERIC_CALL_ERROR;
                    case 'GetUserMediaError':
                        // the message can be sdk_gum_error or stream_config_not_valid
                        // faccio un throw mandando l'utente alla pagine di errore con scritto
                        // Per favore riprovare,  accetta i permessi o controlla di avere wwbcam funzionante
                        throw GUM_ERROR;
                    case 'PublishStreamError':
                        // publish_no_stream or publish_stream_failed or sdk_room_av_not_connected
                        // faccio un throw mandando l'utente alla pagine di errore con scritto
                        // Per favore riprovare, errore generico.
                        throw GENERIC_CALL_ERROR;
                    default:
                        throw e;
                }
            }
            const currentCall = this._bandyerCommunicationCenter.getCall(callAlias);
            const callPermission = currentCall.getCallPermission();
            return this.publishWebCam(callAlias, callPermission);
        }
        throw new Error('[Publish stream] - call alias not valid');
    }

    subscribeStream(stream) {
        this._L.debug('[s.subscribeStream]', stream);
        return this._bandyerAVCore.room.subscribeStream(stream, {
            audio: true,
            video: true,
            data: true
        });
    }

    /**
     * The publish webcam method will publish the webcam stream based on the current call permission.
     * Firstly, it retrieves the callPermission given the "callAlias". After, it calls bandyerAVCore.initLocalWebCam method to acquire the stream.
     * Then, it publish the stream in the AVCore room (licode room).
     * @param {String} callAlias
     * @param {boolean} requestVideoUpgrade
     */
    async publishWebCam(callAlias, config = { audio: true, video: true }) {
        this._L.debug('[s.publishWebCam] - config: ', config);
        try {
            this._L.debug('[s.publishWebCam] - first request: ');
            const state = store.getState();
            const usersDetails = state.usersDetails.get('usersDetails');
            const virtualBackgroundConfig = state.behavior.get('virtualBackground');
            const { userAlias } = this._bandyerCommunicationCenter;
            virtualBackgroundConfig.customImage = usersDetails.has(userAlias)
                ? usersDetails.get(userAlias).get('image')
                : '';
            await this._bandyerAVCore.publisher.initLocalWebCam(config, virtualBackgroundConfig);

            this._bandyerAVCore.publisher.localWebcam.on('stream-ended', (stream) => {
                stream.closeStream();
                dispatcher.setStreamEndedError(true);
            });

            // these dispatchers handles the icon in the call footer
            dispatcher.publisherHasAudio();
            dispatcher.publisherHasVideo();
            await this._bandyerAVCore.room.publishStream(this._bandyerAVCore.publisher.localWebcam);
            const devices = await this.enumerateDevices();
            if (config.audio !== false) {
                dispatcher.setMicrophonePermissionDenied(false);
                dispatcher.setCameraPermissionDenied(false);
                if (devices.every(device => (device.kind === 'videoinput' ? device.label === '' : true))) {
                    dispatcher.setCameraPermissionDenied(true);
                }
            }
            if (config.video && config.video !== false) {
                const currentCall = this._bandyerCommunicationCenter.getCall(callAlias);
                if (currentCall.callType === CALL_TYPE_AUDIO_UPGRADABLE) {
                    currentCall.upgradeVideo();
                }
                // event sent to CommunicationCenter to notify the room that the current user has upgraded the video
            }
            this._handleStreamAddedLocal(this._bandyerAVCore.publisher.localWebcam);
            if (this._bandyerAVCore.publisher.localScreen && this._bandyerAVCore.publisher.localScreen.hasScreen()) {
                // attach local stream if we back to call
                this._handleStreamAddedLocal(this._bandyerAVCore.publisher.localScreen);
            }
            const { stream } = this._bandyerAVCore.publisher.localWebcam;
            if (stream && stream.stream) {
                dispatcher.setMediaStream(stream.stream);
            } else {
                dispatcher.setMediaStream(null);
            }
            this._L.debug('[e.publishWebCam]');
            return;
        } catch (e) {
            this._L.warn('[publishWebCam] - Err', e);
            this._L.warn('[publishWebCam] - Err', e.name);
            this._bandyerAVCore.publisher.stopLocalWebCam();
            switch (e.name) {
                case 'RoomConnectionError':
                    // the message can be room_connection_failed or room_config_token_not_valid
                    // faccio un throw mandando l'utente alla pagine di errore con scritto
                    // Per favore riprovare, errore generico.
                    throw GENERIC_CALL_ERROR;
                case 'GetUserMediaError': {
                    dispatcher.setError(e.message);
                    this._L.debug('[publishWebCam] - GetUserMediaError');
                    // Re publish with no video first, if fail, retry in data only and open the gear
                    const devices = await this.enumerateDevices();
                    this._L.debug('[publishWebCam] - Devices', devices);
                    config.audio = true;
                    config.video = true;
                    if (devices.every(device => (device.kind === 'audioinput' ? device.label === '' : true))) {
                        dispatcher.setMicrophonePermissionDenied(true);
                        dispatcher.setCameraPermissionDenied(true);
                        config.audio = false;
                        config.video = false;
                        this.publishWebCam(callAlias, config);
                        return;
                    }
                    dispatcher.setMicrophonePermissionDenied(false);
                    dispatcher.setCameraPermissionDenied(true);
                    config.video = false;
                    this.publishWebCam(callAlias, config);
                    return;
                }
                case 'PublishStreamError': {
                    // publish_no_stream or publish_stream_failed or sdk_room_av_not_connected
                    // faccio un throw mandando l'utente alla pagine di errore con scritto
                    // Per favore riprovare, errore generico.
                    if (e.message === PUBLISH_STREAM_NOT_VALID) {
                        // this._handleStreamAddedLocal(this._bandyerAVCore.publisher.localWebcam);
                        throw PUBLISH_STREAM_NOT_VALID;
                    }
                    return;
                }
                default:
                    throw e;
            }
        }
    }

    async publishScreen(callAlias, fps) {
        this._L.debug('[s.publishScreen] callAlias', callAlias, 'fps', fps);
        try {
            await this._bandyerAVCore.publisher.initLocalScreen(false, fps, this._screenSharingExtensionId);
            this._bandyerAVCore.publisher.localScreen.on('stream-ended', (stream) => {
                stream.closeStream();
            });
            await this._bandyerAVCore.room.publishStream(this._bandyerAVCore.publisher.localScreen);
            this._handleStreamAddedLocal(this._bandyerAVCore.publisher.localScreen);
            this._L.debug('[e.publishScreen]');
        } catch (e) {
            // todo gestire errore
            throw e;
        }
    }

    async unpublishWebcam() {
        if (this._bandyerAVCore.publisher.localWebcam.stream) {
            return this._bandyerAVCore.room.unpublishStream(this._bandyerAVCore.publisher.localWebcam, true);
        }
        return false;
    }

    async unpublishScreen() {
        if (this._bandyerAVCore.publisher.localScreen.stream) {
            return this._bandyerAVCore.room.unpublishStream(this._bandyerAVCore.publisher.localScreen, true);
        }
        return false;
    }

    async requestPermissionToUpgradeVideo(callAlias) {
        try {
            const result = await this._bandyerCommunicationCenter.requestPermissionToUpgradeVideo(callAlias);
            return result.roomAlias === callAlias;
        } catch (e) {
            return false;
        }
    }

    getCallStatus(callAlias) {
        return this._bandyerCommunicationCenter.getCall(callAlias).callStatus;
    }

    getCallOptions(callAlias) {
        return this._bandyerCommunicationCenter.getCall(callAlias)._callOptions;
    }

    toggleAudio() {
        if (this._bandyerAVCore.publisher && this._bandyerAVCore.publisher.localWebcam) {
            return this._bandyerAVCore.publisher.localWebcam.toggleAudioTrack();
        }
        return null;
    }

    toggleVideo() {
        if (this._bandyerAVCore.publisher && this._bandyerAVCore.publisher.localWebcam) {
            return this._bandyerAVCore.publisher.localWebcam.toggleVideoTrack();
        }
        return null;
    }

    hasAudio() {
        if (this._bandyerAVCore.publisher) {
            return this._bandyerAVCore.publisher.localWebcam.hasAudio();
        }
        return null;
    }

    hasVideo() {
        if (this._bandyerAVCore.publisher) {
            return this._bandyerAVCore.publisher.localWebcam.hasVideo();
        }
        return null;
    }

    focusWindowCall() {
        if (this._callWindow) {
            this._callWindow.focusWindow();
        }
    }

    closeWindowCall() {
        if (this._callWindow) {
            this._callWindow.closeWindow();
        }
    }

    enumerateDevices() {
        return this._bandyerAVCore.publisher.enumerateDevices();
    }

    handleManualRecording(record) {
        this._bandyerCommunicationCenter.handleManualRecording(record);
        return this._bandyerAVCore.room.handleManualRecording(record);
    }

    /**
     * Toggle the virtualBackground functionality, also set the background image if forwarded
     * @param type
     * @param virtualBackground
     * @returns {Promise<void>}
     */
    async toggleVirtualBackground(type, virtualBackground = null) {
        this._L.debug('[toggleVirtualBackground] - type: ', type, ' virtualBackground: ', virtualBackground);
        if (virtualBackground) {
            this._bandyerAVCore.publisher.setVirtualBackground(virtualBackground);
        }
        const toReturn = await this._bandyerAVCore.publisher.toggleVirtualBackground(type);
        this._L.debug('[toggleVirtualBackground] - result: ', toReturn);
        return toReturn;
    }

    static _buildCallData(call) {
        // dato un oggetto call, devo salvare i dati nello store di redux. quali dati mi servono?
        const buildCall = {
            callInfo: call.callInfo,
            callDirection: call.callDirection,
            callStatus: call.callStatus,
            callAlias: call.callAlias,
            callInitiator: call.callInitiator,
            callParticipants: call.callParticipants,
            callType: call.callType
        };
        return buildCall;
    }

    _createObjToEmit = (roomEvent) => {
        const callObj = roomEvent.call;
        const callParticipants = [];
        callObj.callParticipants.forEach((p) => {
            callParticipants.push(p.user.userAlias);
        });
        const toEmit = {
            callAlias: callObj.callAlias,
            callParticipants,
            callDirection: callObj.callDirection === 'outgoing' ? 'outgoing' : 'incoming',
            callOptions: callObj._callOptions
        };
        if (roomEvent.reason) {
            toEmit.reason = roomEvent.reason;
        }
        return toEmit;
    };

    async _registerCallEvents(call) {
        call.on(constants.SDK_EVENTS_CALL_DIAL_ANSWERED, (roomEvent) => {
            dispatcher.updateCall(Call._buildCallData(roomEvent.call));
            this.emit(constants.SDK_EVENTS_CALL_DIAL_ANSWERED, this._createObjToEmit(roomEvent));
        });
        call.on(constants.SDK_EVENTS_CALL_DIAL_DECLINED, (roomEvent) => {
            this.emit(constants.SDK_EVENTS_CALL_DIAL_DECLINED, this._createObjToEmit(roomEvent));
            if (roomEvent.call.callDirection === 'outgoing') {
                this._handleDeclinedEvent(roomEvent);
            }
        });
        call.on(constants.SDK_EVENTS_CALL_DIAL_STOPPED, (roomEvent) => {
            this.emit(constants.SDK_EVENTS_CALL_DIAL_STOPPED, this._createObjToEmit(roomEvent));
            if (roomEvent.call.callDirection === 'outgoing' || roomEvent.reason === 'answered_on_another_device') {
                this._handleStoppedEvent(roomEvent);
            }
        });
        call.on(constants.SDK_EVENTS_CALL_DELETED, (roomEvent) => {
            this._handleRoomDeletedEvent(roomEvent);
        });
        call.on(constants.SDK_EVENTS_ROOM_PARTICIPANT_STATUS_CHANGED, (roomEvent) => {
            this._handleRoomParticipantStatusChanged(roomEvent);
        });
        call.on('call_started', (roomEvent) => {
            this.emit('call_started', this._createObjToEmit(roomEvent));
            if (roomEvent.call.callDirection === 'outgoing') {
                this._handleAnsweredEvent(roomEvent);
            }
        });
        call.on('call_ended', (roomEvent) => {
            this.emit('call_ended', this._createObjToEmit(roomEvent));
        });
    }

    _registerRoomEvents() {
        // todo devo capire quando chiamare questo metodo
        this._bandyerAVCore.room.on(constants.E_BANDYER_CALL_ROOM_CONNECTED, (roomEvent) => {
            this._L.debug(`Call ${constants.E_BANDYER_CALL_ROOM_CONNECTED}`, roomEvent);
            this._handleRoomConnected(roomEvent);
        });
        this._bandyerAVCore.room.on(E_BANDYER_CALL_ROOM_RECONNECTING, () => {
            // dispatch toggle variable for loader on callview
            dispatcher.setReconnectingCall(true);
        });
        this._bandyerAVCore.room.on(E_BANDYER_CALL_ROOM_RECONNECTED, async(roomEvent) => {
            this._L.debug('Call reconnected', roomEvent);
            // republish previous local Webcam
            this._bandyerAVCore.publisher.localWebcam.stream.failed = false;
            await this._bandyerAVCore.room.publishStream(this._bandyerAVCore.publisher.localWebcam);
            this._handleStreamAddedLocal(this._bandyerAVCore.publisher.localWebcam);

            // check for previous localScreen
            if (this._bandyerAVCore.publisher.localScreen && this._bandyerAVCore.publisher.localScreen.hasScreen()) {
                // republish localScreen if exists
                this._bandyerAVCore.publisher.localScreen.stream.failed = false;
                await this._bandyerAVCore.room.publishStream(this._bandyerAVCore.publisher.localScreen);
                this._handleStreamAddedLocal(this._bandyerAVCore.publisher.localScreen);
            }
            const { stream } = this._bandyerAVCore.publisher.localWebcam;
            if (stream && stream.stream) {
                dispatcher.setMediaStream(stream.stream);
            } else {
                dispatcher.setMediaStream(null);
            }
            // subscribe remote streams
            this._handleRoomConnected(roomEvent);
            dispatcher.setReconnectingCall(false);
        });
        this._bandyerAVCore.room.on(constants.E_BANDYER_CALL_ROOM_DISCONNECTED, (roomEvent) => {
            this._L.debug(`Call ${constants.E_BANDYER_CALL_ROOM_DISCONNECTED}`, roomEvent);
            dispatcher.setReconnectingCall(false);
            switch (roomEvent.message) {
                case 'unexpected-disconnection':
                    this.disconnectCall(this._bandyerCommunicationCenter.onGoingCall.callAlias);
                    dispatcher.handleErrorCallSocket();
                    break;
                case 'expected-disconnection':
                    break;
                default:
                    dispatcher.handleErrorCallSocket();
            }
        });
        this._bandyerAVCore.room.on(constants.E_BANDYER_CALL_ROOM_ERROR, () => {
            this._L.debug(`Call ${constants.E_BANDYER_CALL_ROOM_ERROR}`);
            throw GENERIC_CALL_ERROR;
        });
        this._bandyerAVCore.room.on(constants.E_BANDYER_CALL_STREAM_ADDED, (streamEvent) => {
            this._L.debug(`Call ${constants.E_BANDYER_CALL_STREAM_ADDED}`, streamEvent);
            // console.log(`Call  ########## ${constants.E_BANDYER_CALL_STREAM_ADDED}`, roomEvent);
            this._handleStreamAdded(streamEvent);
        });
        this._bandyerAVCore.room.on(constants.E_BANDYER_CALL_STREAM_REMOVED, (streamEvent) => {
            this._L.debug(`Call ${constants.E_BANDYER_CALL_STREAM_REMOVED}`, streamEvent);
            // console.log(`Call AV Core module ########## ${constants.E_BANDYER_CALL_STREAM_REMOVED}`, streamEvent);
            this._handleStreamRemoved(streamEvent);
        });
        this._bandyerAVCore.room.on(constants.E_BANDYER_CALL_STREAM_SUBSCRIBED, (streamEvent) => {
            this._L.debug(`Call ${constants.E_BANDYER_CALL_STREAM_SUBSCRIBED}`, streamEvent);
            // console.log(`Call AV Core module ########## ${constants.E_BANDYER_CALL_STREAM_SUBSCRIBED}`, roomEvent);
            this._handleStreamSubscribed(streamEvent);
        });
        this._bandyerAVCore.room.on(constants.E_BANDYER_CALL_STREAM_FAILED, async(wrappedStream) => {
            this._L.debug(`Call ${constants.E_BANDYER_CALL_STREAM_FAILED}`, wrappedStream);
            dispatcher.setStreamFailedError(true);
            // eslint-disable-next-line no-param-reassign
            delete wrappedStream.stream.failed;
            if (wrappedStream.isLocal()) {
                if (!this._bandyerAVCore.room.isP2p()) {
                    setTimeout(async() => {
                        await this._bandyerAVCore.room.publishStream(wrappedStream);
                        this._handleStreamAddedLocal(wrappedStream);
                    }, 3000);
                }
            } else {
                this._attachStream.handleVideoRemoved(wrappedStream);
                setTimeout(async() => {
                    await this.subscribeStream(wrappedStream);
                }, 3000);
            }
        });
    }

    /**
     * For all streams currently published in the room, it calls the subscribe function
     * @param roomEvent
     * @private
     */
    _handleRoomConnected(roomEvent) {
        // todo finire questo check
        const { streams } = roomEvent;
        this._L.debug('[_handleRoomConnected] - streams: ', roomEvent);
        for (let i = 0; i < streams.length; i++) {
            const stream = streams[i];
            this._L.debug('[_handleRoomConnected] - Subscribing to: ', stream);
            this._bandyerAVCore.room.subscribeStream(stream, {
                audio: true,
                video: true
            });
        }
    }

    _handleRoomDisconnected() {
        if (this._bandyerAVCore.publisher && this._bandyerAVCore.publisher.localWebcam) {
            this._bandyerAVCore.publisher.localWebcam.closeStream();
        }
        if (this._bandyerAVCore.publisher && this._bandyerAVCore.publisher.localScreen) {
            this._bandyerAVCore.publisher.localScreen.closeStream();
        }
    }

    /**
     * If the call has been answered, the user change the view to Call View
     * @param roomEvent
     * @private
     */
    _handleAnsweredEvent(roomEvent) {
        this._L.debug(
            `Call SDK_EVENTS_CALL_DIAL_ANSWERED ########## ${constants.SDK_EVENTS_CALL_DIAL_ANSWERED}`,
            roomEvent
        );
        const { call } = roomEvent;
        dispatcher.updateCall(Call._buildCallData(call));
        const result = dispatcher.getWidgetMode();
        if (result === WIDGET_MODE_WINDOW) {
            dispatcher.changeViewToChannels();
        } else {
            dispatcher.changeViewToCall();
        }
    }

    _handleDeclinedEvent(roomEvent) {
        this._L.debug(
            `Call SDK_EVENTS_CALL_DIAL_DECLINED ########## ${constants.SDK_EVENTS_CALL_DIAL_DECLINED}`,
            roomEvent
        );
        const { call } = roomEvent;
        dispatcher.handleDeclinedCall(buildDeclineReason(call));
        const result = dispatcher.getWidgetMode();
        if (result === WIDGET_MODE_WINDOW) {
            this._callWindow.closeWindow();
            this._callWindow.cleanCallInterval();
        }
    }

    _handleStoppedEvent(roomEvent) {
        this._L.debug(
            `Call SDK_EVENTS_CALL_DIAL_STOPPED ########## ${constants.SDK_EVENTS_CALL_DIAL_STOPPED}`,
            roomEvent
        );
        const { call } = roomEvent;
        const result = dispatcher.getWidgetMode();
        if (result === WIDGET_MODE_WINDOW) {
            this._callWindow.closeWindow();
            this._callWindow.cleanCallInterval();
        }
        dispatcher.handleStoppedCall(call);
        // if (this._activeCalls[call.callAlias].status === BandyerEnum.CallStatus.DIALING) {
        //     dispatcher.handleStoppedCall(this._activeCalls[call.callAlias].call);
        //     this._activeCalls[call.callAlias].status = BandyerEnum.CallStatus.ENDED;
        // }
    }

    _handleRoomDeletedEvent(roomEvent) {
        this._L.debug(`Call SDK_EVENTS_CALL_DELETED ########## ${constants.SDK_EVENTS_CALL_DIAL_STOPPED}`, roomEvent);
        const { call } = roomEvent;
        dispatcher.handleStoppedCall(call);
        const result = dispatcher.getWidgetMode();
        if (result === WIDGET_MODE_WINDOW) {
            this._callWindow.closeWindow();
            this._callWindow.cleanCallInterval();
        }
    }

    _handleRoomParticipantStatusChanged(roomEvent) {
        this._L.debug(
            `Call SDK_EVENTS_ROOM_PARTICIPANT_STATUS_CHANGED ########## ${constants.SDK_EVENTS_ROOM_PARTICIPANT_STATUS_CHANGED}`,
            roomEvent
        );
        const { call } = roomEvent;
        // dispatcher.handleStoppedCall(call);
        // todo qui so che lo stato è cambiato, devo valutare se è one to one e se si disconnette
        const result = dispatcher.getWidgetMode();
        if (call.roomType === constants.ROOM_TYPE_OTO) {
            if (
                result === WIDGET_MODE_WINDOW
                && roomEvent.reason === BandyerEnum.PartecipantStatusReason.CALLING_USER_DISCONNECTED
            ) {
                this._L.debug('[_handleRoomParticipantStatusChanged] - User disconnected:', roomEvent.userAlias);
                this._callWindow.closeWindow();
                this._callWindow.cleanCallInterval();
            }
        }
    }

    /**
     * This fn handle stream. If the user is not local, the user must subscribe it.
     * If the stream is local and is screen, the fn must
     * @param stream
     * @private
     */
    _handleStreamAdded(stream) {
        if (!this._bandyerAVCore.room.isLocalStream(stream)) {
            this.subscribeStream(stream);
        }
    }

    async _handleStreamAddedLocal(stream) {
        if (stream.hasScreen()) {
            this._L.debug('[_handleStreamAddedLocal] - Screen');
            dispatcher.publishScreen(true);
            this._attachStream.handleVideoAdded(stream, LOCAL_SS);
        } else {
            this._L.debug('[_handleStreamAddedLocal] - Webcam', stream);
            dispatcher.publishWebcam(true);
            this._attachStream.handleVideoAdded(stream, LOCAL_WEBCAM);
            // update enumerateDevices
            if (stream.hasAudio() || stream.hasVideo()) {
                dispatcher.getEnumerateDevices();
            }
            const enumerateDevices = await this.enumerateDevices();
            const mediaStream = stream.stream.originalStream.stream;
            if (stream.hasAudio()) {
                const audioDevice = enumerateDevices.find(
                    device => device.label === mediaStream.getAudioTracks()[0].label && device.kind === 'audioinput'
                );
                audioDevice == null
                    ? dispatcher.selectAudioDevice('none')
                    : dispatcher.selectAudioDevice(audioDevice.deviceId);
            } else {
                dispatcher.selectAudioDevice('none');
            }
            if (stream.hasVideo()) {
                const videoDevice = enumerateDevices.find(
                    device => device.label === mediaStream.getVideoTracks()[0].label && device.kind === 'videoinput'
                );
                videoDevice == null
                    ? dispatcher.selectVideoDevice('none')
                    : dispatcher.selectVideoDevice(videoDevice.deviceId);
            } else {
                dispatcher.selectVideoDevice('none');
            }
        }
    }

    _handleStreamSubscribed(stream) {
        if (!this._bandyerAVCore.room.isLocalStream(stream)) {
            // this._attachStream.attachSubscriberWebcam(roomEvent.stream);
            if (stream.hasScreen()) {
                this._attachStream.handleVideoAdded(stream, REMOTE_SS);
                dispatcher.changeMainVideo(SUBSCRIBER_SCREEN);
            } else {
                stream.on('stream-update-config', (attr) => {
                    if (attr.videoMutedRemotely || attr.videoMutedLocally) {
                        dispatcher.setRemoteVideoMuted(true);
                    } else {
                        dispatcher.setRemoteVideoMuted(false);
                    }
                });
                if (stream.stream.isVideoMutedLocally() || stream.stream.isVideoMutedRemotely() || !stream.hasVideo()) {
                    dispatcher.setRemoteVideoMuted(true);
                } else {
                    dispatcher.setRemoteVideoMuted(false);
                }
                this._attachStream.handleVideoAdded(stream, REMOTE_WEBCAM);
                dispatcher.changeMainVideo(SUBSCRIBER_WEBCAM);
            }
        }
    }

    _handleStreamRemoved(stream) {
        if (this._bandyerAVCore.room._room.state !== 0 && this._bandyerAVCore.room.isLocalStream(stream)) {
            if (stream.hasScreen()) {
                dispatcher.publishScreen(false);
            }
        }
        this._attachStream.handleVideoRemoved(stream);
        if (this._attachStream.isVideoInMainVideoContainer(REMOTE_WEBCAM)) {
            dispatcher.changeMainVideo(SUBSCRIBER_WEBCAM);
        } else if (this._attachStream.isVideoInMainVideoContainer(REMOTE_SS)) {
            dispatcher.changeMainVideo(SUBSCRIBER_SCREEN);
        } else {
            dispatcher.changeMainVideo(PUBLISHER_WEBCAM);
        }
    }

    get virtualBackgroundState() {
        return this._bandyerAVCore.publisher.virtualBackgroundState;
    }

    get isVirtualBackgroundSupported() {
        return this._bandyerAVCore.publisher.isVirtualBackgroundSupported;
    }

    get activeCalls() {
        return this._activeCalls;
    }

    set activeCalls(value) {
        this._activeCalls = value;
    }
}

export default Call;
