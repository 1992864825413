import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { VirtualBackgroundService } from '@bandyer/web-core-av';
import Style from './components/widget/style.scss';
import BandyerChatWidget from './components/bandyerChatWidget/BandyerChatWidgetContainer';
import { validateInitWidgetConfig } from './helpers/parseConfig';
import { BandyerChatWidgetNotAuthenticated } from './helpers/errorHandler';
import { BANDYER_CHAT_CONTAINER } from './constants';
import store from './store/store';
import {
    addChannel,
    chatLogout,
    removeChannel,
    initChat,
    selectChat,
    composeMessage,
    getChatList,
    showWidget,
    hideWidget,
    openWidget,
    toggleWidget,
    closeWidget,
    setExtensionUrl,
    getLastMessageSent,
    getLastMessageReceived,
    getUnreadMessages,
    getUserStatus,
    getUsersStatusList,
    createCall,
    joinCallURL
} from './store/actions/dispatcher';

require('./helpers/polyfill');
require('./helpers/prependPolyfill');

const { version } = require('../package.json');

export default {
    // This is the public interface of the widget
    create: (config) => {
        const parsedConfig = validateInitWidgetConfig(config);
        return initChat(parsedConfig).then((bandyerServices) => {
            VirtualBackgroundService.init();
            const chatBandyerDiv = document.createElement('div');
            chatBandyerDiv.id = Style[BANDYER_CHAT_CONTAINER];
            document.body.appendChild(chatBandyerDiv);
            ReactDOM.render(
                <Provider store={store}>
                    <BandyerChatWidget connectionState />
                </Provider>,
                document.getElementById(Style[BANDYER_CHAT_CONTAINER])
            );
            return bandyerServices;
        });
    },
    isAuthenticated: () => store.getState().behavior.get('widgetReady'),
    logout: async() => {
        await chatLogout();
        ReactDOM.unmountComponentAtNode(document.getElementById(Style[BANDYER_CHAT_CONTAINER]));
    },
    addChat(user, options) {
        if (arguments.length !== 1) {
            throw new Error('Missing arguments: (userAlias)');
        }
        if (user && typeof user !== 'string') {
            throw new Error('userAlias must be a valid string');
        }
        if (!store.getState().behavior.get('callTools').chat) {
            throw new Error('Chat module not initialized');
        }
        if (store.getState().behavior.get('widgetReady')) {
            return addChannel(user, options);
        }
        throw new BandyerChatWidgetNotAuthenticated();
    },
    removeChat(user) {
        if (arguments.length !== 1) {
            throw new Error('Missing arguments: (userAlias)');
        }
        if (user && typeof user !== 'string') {
            throw new Error('userAlias must be a valid string');
        }
        if (!store.getState().behavior.get('callTools').chat) {
            throw new Error('Chat module not initialized');
        }
        if (store.getState().behavior.get('widgetReady')) {
            return removeChannel(user);
        }
        throw new BandyerChatWidgetNotAuthenticated();
    },
    selectChat(user) {
        if (arguments.length !== 1) {
            throw new Error('Missing arguments: (userAlias)');
        }
        if (user && typeof user !== 'string') {
            throw new Error('userAlias must be a valid string');
        }
        if (!store.getState().behavior.get('callTools').chat) {
            throw new Error('Chat module not initialized');
        }
        if (store.getState().behavior.get('widgetReady')) {
            return selectChat(user);
        }
        throw new BandyerChatWidgetNotAuthenticated();
    },
    composeMessage(user, text, send) {
        if (arguments.length !== 3) {
            throw new Error('Missing arguments: (userAlias, message, send)');
        }
        if (user && typeof user !== 'string') {
            throw new Error('userAlias must be a valid string');
        }
        if (text && typeof text !== 'string') {
            throw new Error('message must be a valid string');
        }
        if (send && typeof send !== 'boolean') {
            throw new Error('send flag must be true or false');
        }
        if (!store.getState().behavior.get('callTools').chat) {
            throw new Error('Chat module not initialized');
        }
        if (store.getState().behavior.get('widgetReady')) {
            return composeMessage(user, text, send);
        }
        throw new BandyerChatWidgetNotAuthenticated();
    },
    getChats: () => {
        if (!store.getState().behavior.get('callTools').chat) {
            throw new Error('Chat module not initialized');
        }
        if (store.getState().behavior.get('widgetReady')) {
            return getChatList();
        }
        throw new BandyerChatWidgetNotAuthenticated();
    },
    showWidget: () => {
        if (store.getState().behavior.get('widgetReady')) {
            showWidget();
            return true;
        }
        throw new BandyerChatWidgetNotAuthenticated();
    },
    hideWidget: () => {
        if (store.getState().behavior.get('widgetReady')) {
            hideWidget();
            return true;
        }
        throw new BandyerChatWidgetNotAuthenticated();
    },
    openWidget: () => {
        if (store.getState().behavior.get('widgetReady')) {
            openWidget();
            return true;
        }
        throw new BandyerChatWidgetNotAuthenticated();
    },
    toggleWidget: () => {
        if (store.getState().behavior.get('widgetReady')) {
            toggleWidget();
            return true;
        }
        throw new BandyerChatWidgetNotAuthenticated();
    },
    closeWidget: () => {
        if (store.getState().behavior.get('widgetReady')) {
            closeWidget();
            return true;
        }
        throw new BandyerChatWidgetNotAuthenticated();
    },
    setExtensionUrl(url) {
        if (arguments.length !== 1) {
            throw new Error('Missing arguments: (url)');
        }
        if (url && typeof url !== 'string') {
            throw new Error('url must be a valid string');
        }
        setExtensionUrl(url);
    },
    getLastMessageReceived: () => {
        if (!store.getState().behavior.get('callTools').chat) {
            throw new Error('Chat module not initialized');
        }
        if (store.getState().behavior.get('widgetReady')) {
            return getLastMessageReceived();
        }
        throw new BandyerChatWidgetNotAuthenticated();
    },
    getLastMessageSent: () => {
        if (!store.getState().behavior.get('callTools').chat) {
            throw new Error('Chat module not initialized');
        }
        if (store.getState().behavior.get('widgetReady')) {
            return getLastMessageSent();
        }
        throw new BandyerChatWidgetNotAuthenticated();
    },
    getUnreadMessages: () => {
        if (!store.getState().behavior.get('callTools').chat) {
            throw new Error('Chat module not initialized');
        }
        if (store.getState().behavior.get('widgetReady')) {
            return getUnreadMessages();
        }
        throw new BandyerChatWidgetNotAuthenticated();
    },
    getUser(userAlias) {
        if (arguments.length !== 1) {
            throw new Error('Missing arguments: (userAlias)');
        }
        if (userAlias && typeof userAlias !== 'string') {
            throw new Error('userAlias must be a valid string');
        }
        if (store.getState().behavior.get('widgetReady')) {
            return getUserStatus(userAlias);
        }
        throw new BandyerChatWidgetNotAuthenticated();
    },
    getUsersStatusList() {
        if (store.getState().behavior.get('widgetReady')) {
            return getUsersStatusList();
        }
        throw new BandyerChatWidgetNotAuthenticated();
    },
    createCall(participants, options = {}) {
        if (arguments.length !== 2) {
            throw new Error('Missing arguments: (participants, options)');
        }
        if (participants && !Array.isArray(participants)) {
            throw new Error('usersAlias must be an array of string(userAlias)');
        }
        if (options && typeof options !== 'object') {
            throw new Error('options must be an object');
        }
        if (store.getState().behavior.get('widgetReady')) {
            return createCall(participants, options);
        }
        throw new BandyerChatWidgetNotAuthenticated();
    },
    joinCallURL(url) {
        if (arguments.length !== 1) {
            throw new Error('Missing arguments: (url)');
        }
        if (url && typeof url !== 'string') {
            throw new Error('url must be a valid string');
        }
        if (store.getState().behavior.get('widgetReady')) {
            return joinCallURL(url);
        }
        throw new BandyerChatWidgetNotAuthenticated();
    },
    version: () => version
};
