import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Map } from 'immutable';
import { Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as DynamicScreenShareIcon } from '../../../../../../../../assets/icon/ic_bandyer_screenshare_dynamic.svg';
import { ReactComponent as StaticScreenShareIcon } from '../../../../../../../../assets/icon/ic_bandyer_screenshare_static.svg';
import Style from './style.scss';

const StyledMenuItem = withStyles({ root: { '&:hover': { backgroundColor: 'transparent' } } })(MenuItem);

class ScreenShareMenu extends PureComponent {
    constructor() {
        super();
        this.state = { openScreenShareMenu: false };
        this.popperRef = React.createRef();
        window.popperRef = this.popperRef;
    }

    handleClose = () => {
        const { closeScreenShareMenu } = this.props;
        closeScreenShareMenu();
    };

    publishScreenShare = (fps) => {
        const { publishScreen, call } = this.props;
        publishScreen(call, fps);
        this.handleClose();
    }


    render() {
        const { container } = this.props;
        const { openScreenShareMenu } = this.state;
        return (
            <>
                <Popper ref={this.popperRef} anchorEl={container} className={Style['screenshare-menu-popper']} transition disablePortal open placement="bottom">
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                        >
                            <Paper className={Style['screenshare-menu']}>
                                <span className={Style['screenshare-menu-title']}>
                                    <FormattedMessage id="ScreenShareMenu.MenuInfoText" defaultMessage="What do you want to share from the screen?" />
                                </span>
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <MenuList autoFocusItem={openScreenShareMenu}>
                                        <StyledMenuItem disableGutters onClick={() => this.publishScreenShare(5)}>
                                            <div className={Style['screenshare-menuitem']}>
                                                <div className={Style['primary-content']}>
                                                    <span>
                                                        <FormattedMessage id="ScreenShareMenu.MenuStaticContent" defaultMessage="Static contents" />
                                                    </span>
                                                    <StaticScreenShareIcon className={Style['screenshare-menu-icon']} />
                                                </div>
                                                <div className={Style['secondary-content']}>
                                                    <FormattedMessage id="ScreenShareMenu.MenuStaticContentSecondary" defaultMessage="Ideal for document sharing" />
                                                </div>
                                            </div>
                                        </StyledMenuItem>
                                        <StyledMenuItem disableGutters onClick={() => this.publishScreenShare(30)}>
                                            <div className={Style['screenshare-menuitem']}>
                                                <div className={Style['primary-content']}>
                                                    <span>
                                                        <FormattedMessage id="ScreenShareMenu.MenuDynamicContent" defaultMessage="Dynamic contents" />
                                                    </span>
                                                    <DynamicScreenShareIcon className={Style['screenshare-menu-icon']} />
                                                </div>
                                                <div className={Style['secondary-content']}>
                                                    <FormattedMessage id="ScreenShareMenu.MenuDynamicContentSecondary" defaultMessage="Ideal for video and animation sharing" />

                                                </div>
                                            </div>
                                        </StyledMenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </>


        );
    }
}

ScreenShareMenu.propTypes = {
    headerStyle: PropTypes.instanceOf(Map),
    container: PropTypes.shape({ component: PropTypes.instanceOf(React.Component) }),
    closeScreenShareMenu: PropTypes.func,
    publishScreen: PropTypes.func,
    call: PropTypes.instanceOf(Map)
};

ScreenShareMenu.defaultProps = {
    headerStyle: Map({}),
    container: null,
    closeScreenShareMenu: null,
    publishScreen: null,
    call: Map({})
};

export default ScreenShareMenu;
