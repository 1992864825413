import { constants, BandyerEnum } from 'bandyersdkcommon';
import Logger from '../../logger';
import Util from './util';
import { validateAddChannelConfig } from '../../helpers/parseConfig';
import { buildChannelUniqueName } from '../../helpers/utils';
import { UserNotValidError, ChatAddedError, ChatRemoveError } from '../../helpers/errorHandler';

import {
    handleChannelAdded as dispatchChannelAdded,
    handleChannelRemoved as dispatchChannelRemoved,
    handleChanneSelectedAfterRemove, setHaveChat
} from '../../store/actions/dispatcher';
import store from '../../store/store';
import BandyerServices from '../../services'; // eslint-disable-line

const axios = require('axios').default;

const events = require('events');

class Channel extends events {
    constructor(client) {
        super();
        this._L = Logger.scope('Chat - Channel');
        this._channelPrefix = 'ch';
        this._client = client;
        this._Util = new Util(this._client);
    }

    /* Rest to add channel in Twilio Chat. */
    async addChannel(user) {
        this._L.debug('[addChannel from REST ] - user: ', user);
        return axios
            .post(
                `https://${BandyerServices.getInstance().environment}.bandyer.com/rest/sdk/chat/create`,
                {
                    requester: this._client._localUserAlias,
                    users: [user]
                },
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        appid: BandyerServices.getInstance().appId
                    }
                }
            )
            .then((response) => {
                this._L.debug('[addChannel from REST ] - added: ', response);
                return Promise.resolve(response.data.chat);
            })
            .catch((error) => {
                const err = error.response.data.error;
                this._L.debug('[addChannel from REST ] - error: ', err.name);
                switch (err.name) {
                    case 'invalid_users_length':
                        return Promise.reject(new UserNotValidError(err.message));
                    case 'invalid_user':
                        return Promise.reject(new UserNotValidError('The specified user is not valid'));
                    default:
                        return Promise.reject(new ChatAddedError('Impossible to addChat'));
                }
            });
    }

    removeChannel(user) {
        const validatedInput = validateAddChannelConfig(user);
        return axios
            .post(
                `https://${BandyerServices.getInstance().environment}.bandyer.com/rest/sdk/chat/remove`,
                {
                    requester: this._client._localUserAlias,
                    users: [validatedInput.user]
                },
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        appid: BandyerServices.getInstance().appId
                    }
                }
            )
            .then((response) => {
                this._L.debug(
                    '[removeChannel from REST ] - removed: ',
                    response,
                    this._client._localUserAlias,
                    response.data.chat.users[0]
                );
                const uniqueName = buildChannelUniqueName([this._client._localUserAlias, response.data.chat.users[0]]);
                return Promise.resolve(uniqueName);
            })
            .catch((error) => {
                const err = error.response.data.error;
                this._L.debug('[removeChannel from REST ] - error: ', err.name);
                switch (err.code) {
                    case 'chat_not_found':
                        return Promise.reject(new ChatRemoveError('Chat not found'));
                    case 'invalid_user':
                        return Promise.reject(new UserNotValidError('The specified user is not valid'));
                    default:
                        return Promise.reject(new ChatRemoveError('Unable to remove chat'));
                }
            });

        /*
         const validatedInput = validateAddChannelConfig(user);
        const uniqueName = buildChannelUniqueName([this._client._localUserAlias, validatedInput.user]);
        this._L.debug('[removeChannel] - uniqueName', uniqueName);
        return this._getChannelByUniqueName(uniqueName)
            .then(channelRetrieved => Util._removeChannel(channelRetrieved))
            .catch((err) => {
                const errorCode = parseTwilioErrors(err);
                this._L.warn('[removeChannel]', errorCode);
                switch (errorCode) {
                    case CHANNEL_NOT_FOUND:
                        return CHANNEL_NOT_FOUND;
                    default:
                        return REMOVE_CHANNEL_GENERIC_ERROR;
                }
            });
            */
    }

    /**
     * This fn fetch the subscribe channels for the user by calling the [getSubscribedChannels] of Twilio.
     * Once the channels are fetched, it built the channelStructure for the frontend.
     * @returns {Promise.<TResult>|*}
     */
    async fetchSubscribedChannels() {
        const subscribedChannels = await this._client._twilioChatClient.getSubscribedChannels();
        if (subscribedChannels.items && subscribedChannels.items.length) {
            const promises = [];
            for (const ch of subscribedChannels.items) {
                this._client._twilioChatClient.channels[ch.state.uniqueName] = ch;
                promises.push(this._buildChannelStructure(ch));
            }
            return Promise.all(promises);
        }
        return [];
    }

    /**
     * This fn select the current chat requested by the user. It return an object composed by
     * {
     *      uniqueName: 'asd',
     *      dialog: [{},{},..],
     *      who: 'usr_1235',
     *      unreadMessages: Number,
     * }
     * @param uniqueName
     * @param who
     * @returns {*}
     */
    async selectCurrentChannel(uniqueName = null, who) {
        this._L.debug('[selectCurrentChannel] - uniqueName', uniqueName, who);
        if (!uniqueName) {
            this._L.warn('[selectCurrentChannel] - No unique name specified');
            throw new Error('[selectCurrentChannel] - No unique name specified');
        }
        if (this._client._twilioChatClient.channels[uniqueName]) {
            await this._Util.setAllReadMessages(uniqueName);
            const state = store.getState();
            const currentChannel = {};
            currentChannel.uniqueName = uniqueName;
            if (!state.messages.has(uniqueName)) {
                // if i never loaded this channel before
                const members = await this._Util.getMemberLastUnconsumedMessage(uniqueName);
                const messages = await this._client._twilioChatClient.channels[uniqueName].getMessages(20);
                currentChannel.dialog = this._Util.buildDialogObj({
                    messages: messages.items,
                    members,
                    channel: this._client._twilioChatClient.channels[uniqueName]
                });
            }
            currentChannel.who = who;
            const unreadMessages = await Util.getUnconsumedMessages(
                this._client._twilioChatClient.channels[uniqueName]
            );
            currentChannel.unreadMessages = unreadMessages;
            currentChannel._twilioChannel = this._client._twilioChatClient.channels[uniqueName];
            this._client._twilioChatClient.currentChannel = currentChannel;
            return currentChannel;
        }
        this._L.warn("[selectCurrentChannel] - Channel doesn't exist");
        throw new Error("[selectCurrentChannel] - Channel doesn't exist");
    }

    async fetchMessages(uniqueName, anchor) {
        if (!uniqueName) {
            this._L.warn('[selectCurrentChannel] - No unique name specified');
            throw new Error('[selectCurrentChannel] - No unique name specified');
        }
        if (this._client._twilioChatClient.channels[uniqueName]) {
            const messages = await this._client._twilioChatClient.channels[uniqueName].getMessages(20, anchor);
            const members = await this._Util.getMemberLastUnconsumedMessage(uniqueName);
            const toReturn = this._Util.buildDialogObj({
                messages: messages.items,
                members,
                channel: this._client._twilioChatClient.channels[uniqueName]
            });
            return toReturn;
        }
        this._L.warn("[selectCurrentChannel] - Channel doesn't exist");
        throw new Error("[selectCurrentChannel] - Channel doesn't exist");
    }

    // EVENTS

    async handleChannelAdded(channel) {
        try {
            await Util.joinChannel(channel);
            this._client._twilioChatClient.channels[channel.state.uniqueName] = channel;
            const subscribedChannel = await this._buildChannelStructure(channel);
            const isNew = subscribedChannel.dateCreated > this._client._dateCreated;
            dispatchChannelAdded(subscribedChannel, isNew);
            this.emit(constants.SDK_EVENTS_CHAT_LOADED, Channel.filterChannelStructureForEvent(subscribedChannel));
        } catch (e) {
            switch (e.message) {
                case 'noMember':
                    this._L.warn('Invalid channel added - The channel have no member');
                    break;
                case 'joinChannel failed':
                    this._L.debug('Invalid channel added - joinChannel failed');
                    break;
                default:
                    this._L.warn('Invalid channel added', e.message);
            }
        }
    }

    handleChannelRemoved(channel) {
        this._L.debug('_handleChannelRemoved', channel);
        delete this._client._twilioChatClient.channels[channel.state.uniqueName];
        dispatchChannelRemoved(channel.state.uniqueName);
        handleChanneSelectedAfterRemove(channel.state.uniqueName);
        if (this._client._twilioChatClient.channels.channels.size === 0) {
            setHaveChat(false);
        }
        this.emit('chat_removed', channel.state.uniqueName);
        // devo fare dispatch del canale da togliere
    }

    // UTILS
    _getChannelByUniqueName(uniqueName) {
        return this._client._twilioChatClient.getChannelByUniqueName(uniqueName);
    }

    async _buildChannelStructure(channel) {
        const members = await channel.getMembers();
        let status = BandyerEnum.UserStatus.OFFLINE;
        try {
            const participants = [];
            const participantsPromises = [];
            members.forEach(async(member) => {
                const m = member.identity;
                if (m !== this._client._localUserAlias) {
                    const participant = new Promise(async(resolve) => {
                        const userObj = await this._client.bandyerWebSdk.getUserStatus(m);
                        if (userObj.status !== BandyerEnum.UserStatus.OFFLINE) {
                            status = BandyerEnum.UserStatus.ONLINE;
                        }
                        if (userObj.user) {
                            userObj.user.formattedName = userObj.user.userAlias;
                            participants.push(userObj.user);
                        } else {
                            participants.push({ userAlias: m, formattedName: m });
                        }
                        resolve();
                    });
                    participantsPromises.push(participant);
                }
            });
            await Promise.all(participantsPromises);
            if (!participants.length) {
                return Promise.reject(new Error('noMember'));
            }

            const buildChannel = {
                type: 'single',
                uniqueName: channel.uniqueName,
                participants,
                unreadMessage: 0,
                messages: [],
                isTyping: false,
                dateCreated: channel.state.dateCreated,
                createdBy: channel.state.createdBy,
                status
            };
            const channelMessagesInfo = await Util.getChannelMessageInfo(channel, this._client._localUserAlias);
            buildChannel.lastMessage = channelMessagesInfo.lastMessage;
            buildChannel.unreadMessage = channelMessagesInfo.unreadMessage;
            return buildChannel;
        } catch (err) {
            this._L.debug('buildChannelStructure - catch', err);
            switch (err.message) {
                case 'NO_CHANNEL_SELECTED':
                default:
                    throw new UserNotValidError(
                        'Something went wrong with the addChat method. Maybe you specified a not valid user.'
                    );
            }
        }
    }

    static filterChannelStructureForEvent({
        uniqueName,
        participants,
        lastMessage,
        unreadMessage,
        dateCreated,
        createdBy
    }) {
        return {
            /* type, */
            chat: uniqueName,
            participants: [participants],
            lastMessage,
            unreadMessage,
            dateCreated,
            createdBy
        };
    }
}

export default Channel;
