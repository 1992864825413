import actions from './types';
import callActions from '../components/main/components/call/redux/types';
import { parseLocalUser } from '../../../store/reducers/helper';

/** *********** INITIALIZE CONFIGURATION ********* */

function setHideWidget(hide) {
    return {
        type: actions.HIDE_CHAT,
        hide
    };
}

function wasHide(wasHide) {
    return {
        type: actions.WAS_HIDE,
        wasHide
    };
}

function wasClose(wasClose) {
    return {
        type: actions.WAS_CLOSE,
        wasClose
    };
}

function setRecordValue(record) {
    return {
        type: actions.SET_RECORD_VALUE,
        record
    };
}

function setLocalUserInfo(localUserInfo) {
    return {
        type: actions.SET_LOCAL_USER_INFO,
        localUserInfo: parseLocalUser(localUserInfo)
    };
}

function setWidgetReady(widgetReady) {
    return {
        type: actions.WIDGET_READY,
        widgetReady
    };
}

function chatInitHasError(bool) {
    return {
        type: actions.CHAT_HAS_ERROR,
        hasError: bool
    };
}

function setCanVideo(canVideo) {
    return {
        type: actions.SET_CAN_VIDEO,
        canVideo
    };
}

/** *********** MAIN BEHAVIOUR ****************** */

function toggleChat() {
    return { type: actions.TOGGLE_CHAT };
}

function toggleFullScreen() {
    return { type: actions.TOGGLE_FULL_SCREEN };
}

function logout() {
    return { type: actions.LOGOUT };
}

function chatIsLoading(bool) {
    return {
        type: actions.CHAT_IS_LOADING,
        isLoading: bool
    };
}

function changeView(view) {
    return {
        type: actions.CHANGE_VIEW,
        view
    };
}

function showWidget() {
    return { type: actions.SET_CHAT_OPEN };
}

function closeWidget() {
    return { type: actions.SET_CHAT_CLOSE };
}

function setWidgetCallType(widgetCallType) {
    return {
        type: actions.SET_WIDGET_CALL_TYPE,
        widgetCallType
    };
}

function setWidgetMode(widgetMode) {
    return {
        type: actions.SET_WIDGET_MODE,
        widgetMode
    };
}

function setLanguage(language) {
    return {
        type: actions.SET_LANGUAGE,
        language
    };
}

function setChatInitialized(chat) {
    return {
        type: actions.SET_CHAT_INIT,
        chat
    };
}

function setTools(callTools) {
    return {
        type: actions.SET_CALL_TOOLS,
        callTools
    };
}

function setVirtualBackgroundConfig(virtualBackground) {
    return {
        type: actions.SET_VIRTUAL_BACKGROUND,
        virtualBackground
    };
}

/** *********** MESSAGES BEHAVIOUR ****************** */

function addUserMessage(uniqueName, messageObj) {
    return {
        type: actions.ADD_NEW_USER_MESSAGE,
        uniqueName,
        messageObj
    };
}

function emptyMessages() {
    return { type: actions.EMPTY_MESSAGE };
}

function updateUnreadMessagePerChannel(uniqueName, unreadMessage) {
    return {
        type: actions.UPDATE_UNREAD_MESSAGE_PER_CHANNEL,
        unreadMessage,
        uniqueName
    };
}

function setMessages(messages, uniqueName) {
    return {
        type: actions.SET_MESSAGE,
        messages,
        uniqueName
    };
}

function addResponseMessage(uniqueName, messageObj) {
    return {
        type: actions.ADD_NEW_RESPONSE_MESSAGE,
        uniqueName,
        messageObj
    };
}
function removeChat(uniqueName) {
    return {
        type: actions.REMOVE_CHAT,
        uniqueName
    };
}

function setHaveChat(haveChat) {
    return {
        type: actions.HAVE_CHAT,
        haveChat
    };
}

function toggleInputDisabled() {
    return { type: actions.TOGGLE_INPUT_DISABLED };
}

function setExtensionUrl(extensionUrl) {
    return {
        type: callActions.SET_EXTENSION_URL,
        extensionUrl
    };
}

/** **********************************************************************************************************
 ************************************************ ACTIVITY CALL ********************************************
 *********************************************************************************************************** */

function updateLastMessageSent(message) {
    return {
        type: actions.UPDATE_MESSAGE_SENT,
        message
    };
}

function updateLastMessageReceived(message) {
    return {
        type: actions.UPDATE_MESSAGE_RECEIVED,
        message
    };
}

function setUnreadMessagesPerChannel(channelUniqueName, unreadMessages) {
    return {
        type: actions.SET_UNREAD_MESSAGE_PER_CHANNEL,
        channelUniqueName,
        unreadMessages
    };
}

/** *********** CHANNELS BEHAVIOUR ****************** */

function setChannel(selectedChannel) {
    return {
        type: actions.SELECT_CHANNEL,
        selectedChannel
    };
}

function fetchedSubscribedChannels(subscribedChannels) {
    return {
        type: actions.SUBSCRIBED_CHANNELS_FETCHED,
        subscribedChannels
    };
}

function removeSubscribedChannel(selectedChannel) {
    return (dispatch) => {
        dispatch({
            type: actions.REMOVE_CHANNEL,
            selectedChannel
        });
    };
}

function updateChannelLastMessage(uniqueName, lastMessage = {}) {
    return {
        type: actions.UPDATE_LAST_MESSAGE,
        lastMessage,
        uniqueName
    };
}

function channelIsTyping(uniqueName, isTyping = false) {
    return {
        type: actions.IS_TYPING,
        isTyping,
        uniqueName
    };
}

function selectCurrentChannel(selectedChannel) {
    return {
        type: actions.SELECT_CHANNEL,
        selectedChannel
    };
}

function fetchedSubscribedChannelsNoResults(subscribedChannels) {
    return {
        type: actions.SUBSCRIBED_CHANNELS_FETCHED_NO_RESULTS,
        subscribedChannels
    };
}

function setUserStatusInChannel(uniqueName, status = 'offline') {
    return {
        type: actions.SET_USER_STATUS_PER_CHANNEL,
        status,
        uniqueName
    };
}

export default {
    toggleChat,
    toggleFullScreen,
    addUserMessage,
    logout,
    chatIsLoading,
    setHideWidget,
    setRecordValue,
    setLocalUserInfo,
    chatInitHasError,
    setWidgetReady,
    emptyMessages,
    setChannel,
    updateUnreadMessagePerChannel,
    setMessages,
    changeView,
    addResponseMessage,
    toggleInputDisabled,
    fetchedSubscribedChannels,
    removeSubscribedChannel,
    channelIsTyping,
    updateChannelLastMessage,
    showWidget,
    selectCurrentChannel,
    closeWidget,
    setExtensionUrl,
    updateLastMessageReceived,
    updateLastMessageSent,
    setUnreadMessagesPerChannel,
    fetchedSubscribedChannelsNoResults,
    setWidgetCallType,
    setCanVideo,
    setWidgetMode,
    setLanguage,
    setUserStatusInChannel,
    removeChat,
    setHaveChat,
    wasClose,
    setChatInitialized,
    setVirtualBackgroundConfig,
    setTools,
    wasHide
};
