import { Map, List } from 'immutable';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import React, { PureComponent } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import { CircularProgress, Tooltip, SvgIcon } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import FullScreen from './components/FullScreen';
import ScreenShare from './components/ScreenShare';
import ScreenShareExtension from './components/ScreenShareExtension';
import Footer from './components/Footer';
import CanVideoSnackBar from './snackbar/canVideo';
import AudioOnlySnackBar from './snackbar/audioOnly';
import NoAvailableMicrophone from './snackbar/noAvailableMicrophone';
import CallLoader from './components/CallLoader/CallLoaderComponent';
import {
    CALL_TYPE_AUDIO_ONLY,
    CALL_TYPE_AUDIO_UPGRADABLE,
    CALL_TYPE_AUDIO_VIDEO,
    NO_AVAILABLE_MICROPHONE,
    CALL,
    SUBSCRIBER_WEBCAM,
    REMOTE_WEBCAM,
    LOCAL_WEBCAM
} from '../../../../../../constants';
import BackToChat from './components/BackToChat';
import GearButton from './components/GearButton';
import Gear from './components/Gear';
import Logger from '../../../../../../logger';
import RequestPermission from './components/RequestPermission';
import SnackbarVariant from './snackbar/snackbarVariant';
import { fetchUserImage } from '../../../../../../helpers/utils';

import { ReactComponent as VerificationImage } from '../../../../../../assets/icon/verified_user_white-24px.svg';
import FileShareButton from './components/FileShareButton';
import FileShare from '../fileShare/FileShare';

import Style from './style.scss';
import RecordingBadge from './components/RecordingBadge';
import RequestPlayPermission from './components/RequestPlayPermission';
import ScreenShareMenu from './components/screenShareMenu';

class CallComponent extends PureComponent {
    state = {
        openSnackBar: false,
        reasonSnackBar: '',
        loader: false,
        animation: true,
        showGear: false,
        showPermission: false,
        togglePermission: false,
        firstTry: true,
        openSelectCamera: false,
        openSelectMicrophone: false,
        snackbarError: false,
        toggleVerification: false,
        showFileShare: false,
        downloadNotification: 0,
        openScreenShareMenu: false,
        forceStopAnimation: false
    };

    componentDidMount = () => {
        const { call, localUser, publishStream, record, microphonePermissionDenied } = this.props;
        this.callContainerRef = React.createRef();
        this.callToolRef = React.createRef();
        publishStream(call, localUser, record);
        this.setState({ animation: false, firstTry: true });
        this.mediaStreamPromise = null;
        if (microphonePermissionDenied) {
            this.setState({ showPermission: true, togglePermission: true });
        }
        this.pendingAnimation = false;
        this.animationTimer = null;
    };

    componentDidUpdate = (prevProps, prevState) => {
        const {
            microphonePermissionDenied,
            webcamPublished,
            error,
            verification,
            remoteMuted,
            mainVideo,
            videoMuted,
            filesToDownload
        } = this.props;
        const { firstTry, showPermission, snackbarError, downloadNotification } = this.state;
        if (prevProps.microphonePermissionDenied !== microphonePermissionDenied) {
            if (microphonePermissionDenied) {
                this.toggleGear();
            }
        }
        if (firstTry && showPermission && webcamPublished) {
            if (!microphonePermissionDenied) {
                this.closePermission();
            } else {
                this.setState({ firstTry: false });
            }
        }
        if (prevProps.verification !== verification) {
            this.toggleVerification(true);
        }

        if (prevProps.error === null && error !== null && !prevState.snackbarError && !snackbarError) {
            this.showErrorSnackbar(true);
        }
        if (prevProps.remoteMuted !== remoteMuted || prevProps.mainVideo !== mainVideo) {
            this.handleCarousel(REMOTE_WEBCAM, remoteMuted);
        }
        if (prevProps.videoMuted !== videoMuted) {
            this.handleCarousel(LOCAL_WEBCAM, videoMuted);
        }
        if (prevProps.filesToDownload.size < filesToDownload.size) {
            this.setDownloadNotification(downloadNotification + 1);
        }
        this.setState({ loader: false });
    };

    componentWillUnmount = () => {
        this.closePermission();
    };

    // eslint-disable-next-line react/sort-comp
    toggleAudio = async() => {
        const { call, toggleAudio, videoHasAudioTrack, microphonePermissionDenied } = this.props;
        // todo qui dovrei valutare se il microfono è stato acquisito o no
        try {
            if (videoHasAudioTrack) {
                toggleAudio(call);
                return true;
            }
            if (!microphonePermissionDenied) {
                this.setState({ openSelectMicrophone: true });
            }
            this.toggleGear();
            return true;
        } catch (err) {
            Logger.debug('[toggleAudio] - error', err);
            if (err === NO_AVAILABLE_MICROPHONE) {
                this.setState({ openSnackBar: true, reasonSnackBar: NO_AVAILABLE_MICROPHONE });
            }
            return true;
        }
    };

    toggleVideo = async() => {
        const { call, canVideo, toggleVideo, videoHasVideoTrack, microphonePermissionDenied } = this.props;
        switch (call.get('callType')) {
            case CALL_TYPE_AUDIO_VIDEO:
            case CALL_TYPE_AUDIO_UPGRADABLE:
                if (canVideo) {
                    if (videoHasVideoTrack) {
                        toggleVideo(call);
                        return true;
                    }
                    if (!microphonePermissionDenied) {
                        this.setState({ openSelectCamera: true });
                    }
                    this.toggleGear();
                    return true;
                }
                this.setState({ openSnackBar: true, reasonSnackBar: CALL_TYPE_AUDIO_UPGRADABLE });
                return false;
            case CALL_TYPE_AUDIO_ONLY:
                this.setState({ openSnackBar: true, reasonSnackBar: CALL_TYPE_AUDIO_ONLY });
                return false;
            default:
                return false;
        }
    };

    setReasonSnackBar = (reasonSnackBar) => {
        this.setState({ reasonSnackBar });
    };

    displaySnackBar = () => {
        const { reasonSnackBar } = this.state;
        switch (reasonSnackBar) {
            case CALL_TYPE_AUDIO_VIDEO:
            case CALL_TYPE_AUDIO_UPGRADABLE:
                return <CanVideoSnackBar handleClose={this.handleClose} />;
            case CALL_TYPE_AUDIO_ONLY:
                return <AudioOnlySnackBar handleClose={this.handleClose} />;
            case NO_AVAILABLE_MICROPHONE:
                return <NoAvailableMicrophone handleClose={this.handleClose} />;
            default:
                break;
        }
        return null;
    };

    disconnectCall = (call) => {
        const { disconnectCall } = this.props;
        this.closePermission();
        disconnectCall(call);
    };

    toggleScreenShare = () => {
        const { call, publishScreen, screenPublished, unpublishScreen } = this.props;
        if (screenPublished) {
            return unpublishScreen(call);
        }
        if (!this.mediaStreamPromise) {
            this.setState({ openScreenShareMenu: true, animation: true, forceStopAnimation: true });
            // return publishScreen(call);
        }
    };

    toggleFileShare = () => {
        const { showFileShare } = this.state;
        this.setState({ showFileShare: !showFileShare });
    };

    getParticipantsName = () => {
        const { call, usersDetails, localUser } = this.props;
        const callParticipants = call.get('callParticipants');

        const localUserAlias = localUser.get('userAlias');
        let toReturn = '';
        for (const p of callParticipants) {
            const userAlias = p.get('user').get('userAlias');
            if (localUserAlias !== userAlias) {
                const formattedName = usersDetails.has(userAlias)
                    ? usersDetails.get(userAlias).get('formattedName')
                    : userAlias;
                toReturn += `${formattedName} `;
            }
        }
        return toReturn;
    };

    getParticipantsAlias = () => {
        const { call, localUser } = this.props;
        const callParticipants = call.get('callParticipants');
        const localUserAlias = localUser.get('userAlias');
        let toReturn = '';
        for (const p of callParticipants) {
            const userAlias = p.get('user').get('userAlias');
            if (localUserAlias !== userAlias) {
                toReturn = userAlias;
            }
        }
        return toReturn;
    };

    closeScreenSharingAlert = () => {
        const { closeAlert } = this.props;
        closeAlert();
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ openSnackBar: false });
    };

    buildClassName(className) {
        const { fullScreenMode } = this.props;
        return fullScreenMode ? className.concat(' ', Style['full-screen']) : className;
    }

    buildClassNameAnimation(className) {
        const { animation } = this.state;
        if (this.pendingAnimation && animation) {
            return className.concat(' ', Style['show-no-time']);
        }
        this.pendingAnimation = true;
        return animation ? className.concat(' ', Style.show) : className.concat(' ', Style.hide);
    }

    setAnimation(value) {
        const { forceStopAnimation } = this.state;
        if (!forceStopAnimation) {
            if (this.animationTimer) {
                clearTimeout(this.animationTimer);
            }
            this.animationTimer = setTimeout(() => {
                this.pendingAnimation = false;
            }, 4000);
            this.setState({ animation: value });
        }
    }

    toggleGear = () => {
        const { microphonePermissionDenied } = this.props;
        const { showGear } = this.state;
        if (microphonePermissionDenied) {
            this.setState({ showPermission: true, togglePermission: true, showGear: false });
        } else {
            this.setState({ showGear: !showGear });
            if (showGear) {
                // if open, next state will be close, so also close the selects
                this.setState({ openSelectCamera: false, openSelectMicrophone: false });
            }
        }
    };

    closePermission = () => {
        this.setState({ showPermission: false, togglePermission: false, firstTry: false });
    };

    togglePermission = (value) => {
        this.setState({ togglePermission: value });
    };

    showErrorSnackbar = (value) => {
        this.setState({ snackbarError: value });
    };

    snackBarErrorMessage() {
        const { error, intl } = this.props;
        let toReturn;
        switch (error) {
            case 'noPermission':
                toReturn = intl.formatMessage({
                    id: 'Stream.error.noPermission',
                    defaultMessage: 'Stream access denied'
                });
                break;
            case 'deviceNotFound':
                toReturn = intl.formatMessage({
                    id: 'Stream.error.deviceNotFound',
                    defaultMessage: 'Stream access denied'
                });
                break;
            case 'deviceAlreadyInUse':
                toReturn = intl.formatMessage({
                    id: 'Stream.error.deviceAlreadyInUse',
                    defaultMessage: 'Stream access denied'
                });
                break;
            default:
                toReturn = intl.formatMessage({
                    id: 'Stream.error.noPermission',
                    defaultMessage: 'Stream access denied'
                });
        }

        return toReturn;
    }

    toggleVerification = (value) => {
        this.setState({ toggleVerification: value });
    };

    handleCarousel = (webCamType, muted) => {
        // WebCamType can be REMOTE_WEBCAM or LOCAL_WEBCAM;
        const { mainVideo } = this.props;
        const carouselCam = document
            .getElementById(Style['carousel-video-container'])
            .querySelector(`.${Style[webCamType]}`);
        const userNoVideoClass = 'user-no-video';
        if (mainVideo !== webCamType) {
            if (carouselCam) {
                if (!carouselCam.querySelector(`.${userNoVideoClass}`)) {
                    carouselCam.appendChild(this.createProfileImg(webCamType));
                }
                const noVideoAvatar = carouselCam.querySelector(`.${userNoVideoClass}`);
                let video = carouselCam.querySelector('video');
                if (!video) {
                    video = carouselCam.querySelector('object');
                }
                if (muted) {
                    video.hidden = true;
                    noVideoAvatar.style.display = 'flex';
                    video.style.display = 'none';
                } else {
                    video.hidden = false;
                    video.play();
                    noVideoAvatar.style.display = 'none';
                    video.style.display = 'flex';
                }
            }
        }
    };

    createProfileImg(webCamType) {
        const { usersDetails, localUser } = this.props;
        const divContainer = document.createElement('div');
        const img = document.createElement('img');
        if (webCamType === REMOTE_WEBCAM) {
            img.src = fetchUserImage(usersDetails.get(this.getParticipantsAlias()));
        } else {
            img.src = fetchUserImage(usersDetails.get(localUser.get('userAlias')));
        }
        img.className = 'profile-img-call'.concat(' ', 'rounded');
        divContainer.className = 'user-no-video';
        divContainer.appendChild(img);
        return divContainer;
    }

    setDownloadNotification = (downloadNotification) => {
        this.setState({ downloadNotification });
    };

    isGetDisplayMediaSupported = () => {
        if (
            typeof navigator !== 'undefined'
            && 'mediaDevices' in navigator
            && 'getDisplayMedia' in navigator.mediaDevices
        ) {
            return true;
        }
        if (typeof navigator !== 'undefined' && 'getDisplayMedia' in navigator) {
            return true;
        }
        return false;
    };

    isMobileDevice = () => {
        if (window.DetectRTC.isMobileDevice) {
            return true;
        }
        return window.DetectRTC.browser.name === 'Safari' ? !this.isGetDisplayMediaSupported() : false;
    };

    render() {
        const {
            callStyle,
            headerStyle,
            fullScreenMode,
            screenPublished,
            toggleFullScreen,
            audioMuted,
            videoMuted,
            canVideo,
            showExtensionAlert,
            extensionUrl,
            call,
            videoHasAudioTrack,
            videoHasVideoTrack,
            changeView,
            addChannel,
            localUser,
            selectChannel,
            notification,
            channels,
            currentView,
            getMediaDevices,
            mediaDevices,
            selectedAudioDevice,
            selectedVideoDevice,
            selectAudioDevice,
            selectVideoDevice,
            changeDeviceSource,
            cameraPermissionDenied,
            microphonePermissionDenied,
            setCameraPermissionDenied,
            setMicrophonePermissionDenied,
            mediaStream,
            streamEnded,
            streamFailed,
            setStreamEndedError,
            setStreamFailedError,
            intl,
            reconnectingCall,
            verification,
            remoteMuted,
            usersDetails,
            mainVideo,
            filesToDownload,
            filesUploaded,
            addFileUploaded,
            updateDownloadFile,
            removeFileUploaded,
            recordingInfo,
            handleManualRecording,
            isAdmin,
            showRequestPlayPermission,
            failedMediaElements,
            setRequestPlayPermission,
            callTools,
            publishScreen
        } = this.props;

        const {
            openSnackBar,
            loader,
            showGear,
            showPermission,
            togglePermission,
            firstTry,
            openSelectCamera,
            openSelectMicrophone,
            snackbarError,
            toggleVerification,
            showFileShare,
            downloadNotification,
            openScreenShareMenu
        } = this.state;
        const streamEndedLabel = intl.formatMessage({
            id: 'Stream.ended',
            defaultMessage: 'Hardware error'
        });
        const streamFailedLabel = intl.formatMessage({
            id: 'Stream.failed',
            defaultMessage: 'Network error! Retrying...'
        });
        const verified = intl.formatMessage({
            id: 'SnackBar.Verified',
            defaultMessage: ' is verified'
        });
        const notVerified = intl.formatMessage({
            id: 'SnackBar.NotVerified',
            defaultMessage: ' is not verified'
        });
        const connecting = intl.formatMessage({
            id: 'Call.connecting',
            defaultMessage: 'Connecting...'
        });
        return (
            <div
                ref={this.callContainerRef}
                className={this.buildClassName(Style['call-container'])}
                onMouseMove={() => this.setAnimation(true)}
                onMouseLeave={() => this.setAnimation(false)}
                style={{
                    color: callStyle.get('color'),
                    background: callStyle.get('background'),
                    display: currentView === CALL ? 'block' : 'none'
                }}
            >
                {reconnectingCall && (
                    <div className={Style.reconnectingCall}>
                        <CircularProgress className={Style.circularProgress} size={60} />
                        <span className={Style.reconnectingMessage}>{connecting}</span>
                    </div>
                )}
                <div
                    id={Style['call-top-container']}
                    className={this.buildClassNameAnimation(Style['call-top-container'])}
                >
                    <div className={Style['call-top-buttons']} ref={this.callToolRef}>
                        {callTools.chat && (
                            <BackToChat
                                call={call}
                                headerStyle={headerStyle}
                                addChannel={addChannel}
                                localUser={localUser}
                                selectChannel={selectChannel}
                                changeView={changeView}
                                size="small"
                                notification={notification}
                                channels={channels}
                            />
                        )}
                        {callTools.screen_sharing && !this.isMobileDevice() && (
                            <ScreenShare
                                screenPublished={screenPublished}
                                toggleScreen={this.toggleScreenShare}
                                headerStyle={headerStyle}
                            />
                        )}
                        <FullScreen
                            fullScreenMode={fullScreenMode}
                            toggleFullScreen={toggleFullScreen}
                            headerStyle={headerStyle}
                        />
                        {callTools.file_upload && (
                            <FileShareButton
                                headerStyle={headerStyle}
                                toggleFileShare={this.toggleFileShare}
                                downloadNotification={downloadNotification}
                            />
                        )}
                        <GearButton headerStyle={headerStyle} toggleGear={this.toggleGear} />
                    </div>
                    <div className={Style.user}>
                        <div className={Style.info}>
                            {verification && verification.verified ? (
                                <Tooltip title={verified}>
                                    <span>
                                        <SvgIcon component={VerificationImage} className={Style.images} />
                                    </span>
                                </Tooltip>
                            ) : (
                                <PersonIcon className={Style.images} />
                            )}
                            <span className={Style.userName}>{this.getParticipantsName()}</span>
                        </div>
                    </div>
                </div>

                <div className={Style['call-video-container']}>
                    {loader && <CallLoader />}
                    <div id={Style['main-video-container']} className={this.buildClassName('')} />
                    {recordingInfo.recording && <RecordingBadge />}
                    {remoteMuted && mainVideo === SUBSCRIBER_WEBCAM && (
                        <div className={Style.remoteVideoMuted}>
                            <img
                                className={`${Style['profile-img-call']} ${Style.rounded}`}
                                alt="profile-img"
                                src={fetchUserImage(usersDetails.get(this.getParticipantsAlias()))}
                            />
                        </div>
                    )}
                    <div id={Style['carousel-video-container']} />
                </div>
                <div className={this.buildClassNameAnimation(Style['call-footer-container'])}>
                    <Footer
                        audioMuted={audioMuted}
                        videoMuted={videoMuted}
                        toggleAudio={this.toggleAudio}
                        toggleVideo={this.toggleVideo}
                        hangUp={this.disconnectCall}
                        canVideo={canVideo}
                        call={call}
                        videoHasAudioTrack={videoHasAudioTrack}
                        videoHasVideoTrack={videoHasVideoTrack}
                        cameraPermissionDenied={cameraPermissionDenied}
                        microphonePermissionDenied={microphonePermissionDenied}
                    />
                </div>
                {showExtensionAlert && (
                    <ScreenShareExtension
                        closeAlert={this.closeScreenSharingAlert}
                        extensionUrl={extensionUrl}
                        headerStyle={headerStyle}
                    />
                )}

                {showFileShare && (
                    <FileShare
                        showFileShare={showFileShare}
                        container={this.callContainerRef.current}
                        toggleFileShare={this.toggleFileShare}
                        headerStyle={headerStyle}
                        call={call.get('callAlias')}
                        localUser={localUser}
                        filesToDownload={filesToDownload}
                        filesUploaded={filesUploaded}
                        addFileUploaded={addFileUploaded}
                        updateDownloadFile={updateDownloadFile}
                        downloadNotification={downloadNotification}
                        setDownloadNotification={this.setDownloadNotification}
                        networkError={reconnectingCall}
                        removeFileUploaded={removeFileUploaded}
                    />
                )}

                {openScreenShareMenu && (
                    <ScreenShareMenu
                        headerStyle={headerStyle}
                        container={this.callToolRef.current}
                        closeScreenShareMenu={() => { this.setState({ openScreenShareMenu: false, forceStopAnimation: false }); }}
                        publishScreen={publishScreen}
                        call={call}
                    />
                )}

                {showGear && (
                    <Gear
                        localMuted={videoMuted}
                        showGear={showGear}
                        container={this.callContainerRef.current}
                        toggleGear={this.toggleGear}
                        getMediaDevices={getMediaDevices}
                        mediaDevices={mediaDevices}
                        selectedAudioDevice={selectedAudioDevice}
                        selectedVideoDevice={selectedVideoDevice}
                        selectAudioDevice={selectAudioDevice}
                        selectVideoDevice={selectVideoDevice}
                        changeDeviceSource={changeDeviceSource}
                        callAlias={call.get('callAlias')}
                        callType={call.get('callType')}
                        localUser={localUser}
                        canVideo={canVideo}
                        cameraPermissionDenied={cameraPermissionDenied}
                        setCameraPermissionDenied={setCameraPermissionDenied}
                        microphonePermissionDenied={microphonePermissionDenied}
                        setMicrophonePermissionDenied={setMicrophonePermissionDenied}
                        mediaStream={mediaStream}
                        audioMuted={audioMuted}
                        openSelectCamera={openSelectCamera}
                        openSelectMicrophone={openSelectMicrophone}
                        isAdmin={isAdmin}
                        recordingInfo={recordingInfo}
                        handleManualRecording={handleManualRecording}
                    />
                )}
                {showPermission && (
                    <RequestPermission
                        showPermission={togglePermission}
                        selectedAudioDevice={selectedAudioDevice}
                        container={this.callContainerRef.current}
                        closePermission={this.closePermission}
                        togglePermission={this.togglePermission}
                        callType={call.get('callType')}
                        toggleGear={this.toggleGear}
                        setCameraPermissionDenied={setCameraPermissionDenied}
                        setMicrophonePermissionDenied={setMicrophonePermissionDenied}
                        firstTry={firstTry}
                        getMediaDevices={getMediaDevices}
                    />
                )}

                {showRequestPlayPermission && this.callContainerRef && (
                    <RequestPlayPermission
                        showRequestPlayPermission={showRequestPlayPermission}
                        failedMediaElements={failedMediaElements}
                        setRequestPlayPermission={setRequestPlayPermission}
                        container={this.callContainerRef.current}
                    />
                )}
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    open={streamEnded}
                    onEntering={() => {
                        !showGear ? this.toggleGear() : null;
                    }}
                    autoHideDuration={6000}
                    onClose={() => {
                        setStreamEndedError(false);
                    }}
                    style={{ position: 'absolute' }}
                >
                    <SnackbarVariant
                        onClose={() => {
                            setStreamEndedError(false);
                        }}
                        variant="error"
                        message={streamEndedLabel}
                    />
                </Snackbar>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    open={streamFailed}
                    autoHideDuration={6000}
                    onClose={() => {
                        setStreamFailedError(false);
                    }}
                    style={{ position: 'absolute' }}
                >
                    <SnackbarVariant
                        onClose={() => {
                            setStreamFailedError(false);
                        }}
                        variant="warning"
                        message={streamFailedLabel}
                    />
                </Snackbar>

                {/* SNACKBAR VERIFICATION MESSAGE */}
                {verification && (
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}
                        open={toggleVerification}
                        // autoHideDuration={6000}
                        onClose={() => {
                            this.toggleVerification(false);
                        }}
                    >
                        <SnackbarVariant
                            onClose={() => {
                                this.toggleVerification(false);
                            }}
                            variant={verification.verified ? 'success' : 'error'}
                            message={
                                verification.verified
                                    ? verification.userId.concat(verified)
                                    : verification.userId.concat(notVerified)
                            }
                        />
                    </Snackbar>
                )}
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    open={snackbarError}
                    onClose={() => {
                        this.showErrorSnackbar(false);
                    }}
                    style={{ position: 'absolute' }}
                >
                    <SnackbarVariant
                        onClose={() => {
                            this.showErrorSnackbar(false);
                        }}
                        variant="warning"
                        message={this.snackBarErrorMessage()}
                        style={{ fontSize: '10px' }}
                    />
                </Snackbar>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    style={{ position: 'absolute' }}
                    onClose={this.handleClose}
                    open={openSnackBar}
                    ContentProps={{ 'aria-describedby': 'message-id' }}
                    disableWindowBlurListener
                    autoHideDuration={6000}
                >
                    {this.displaySnackBar()}
                </Snackbar>
            </div>
        );
    }
}

CallComponent.propTypes = {
    call: PropTypes.instanceOf(Map),
    publishStream: PropTypes.func,
    audioMuted: PropTypes.bool,
    videoMuted: PropTypes.bool,
    toggleAudio: PropTypes.func,
    toggleVideo: PropTypes.func,
    disconnectCall: PropTypes.func,
    webcamPublished: PropTypes.bool,
    screenPublished: PropTypes.bool,
    canVideo: PropTypes.bool,
    publishScreen: PropTypes.func,
    unpublishScreen: PropTypes.func,
    toggleFullScreen: PropTypes.func,
    fullScreenMode: PropTypes.bool,
    extensionUrl: PropTypes.string,
    closeAlert: PropTypes.func,
    changeView: PropTypes.func,
    showExtensionAlert: PropTypes.bool,
    headerStyle: PropTypes.instanceOf(Map),
    callStyle: PropTypes.instanceOf(Map),
    localUser: PropTypes.instanceOf(Map),
    videoHasAudioTrack: PropTypes.bool,
    videoHasVideoTrack: PropTypes.bool,
    addChannel: PropTypes.func,
    selectChannel: PropTypes.func,
    record: PropTypes.bool,
    channels: PropTypes.instanceOf(List),
    notification: PropTypes.number,
    currentView: PropTypes.string,
    getMediaDevices: PropTypes.func,
    mediaDevices: PropTypes.instanceOf(Array),
    selectedAudioDevice: PropTypes.string,
    selectedVideoDevice: PropTypes.string,
    selectAudioDevice: PropTypes.func,
    selectVideoDevice: PropTypes.func,
    changeDeviceSource: PropTypes.func,
    cameraPermissionDenied: PropTypes.bool,
    setCameraPermissionDenied: PropTypes.func,
    microphonePermissionDenied: PropTypes.bool,
    setMicrophonePermissionDenied: PropTypes.func,
    mediaStream: PropTypes.any,
    streamEnded: PropTypes.bool,
    streamFailed: PropTypes.bool,
    setStreamEndedError: PropTypes.func,
    setStreamFailedError: PropTypes.func,
    intl: intlShape.isRequired,
    reconnectingCall: PropTypes.bool,
    error: PropTypes.string,
    verification: PropTypes.instanceOf(Object),
    usersDetails: PropTypes.instanceOf(Map),
    remoteMuted: PropTypes.bool,
    mainVideo: PropTypes.string,
    filesToDownload: PropTypes.instanceOf(Map),
    filesUploaded: PropTypes.instanceOf(Map),
    addFileUploaded: PropTypes.func,
    updateDownloadFile: PropTypes.func,
    removeFileUploaded: PropTypes.func,
    recordingInfo: PropTypes.exact({
        recording: PropTypes.bool,
        recordingType: PropTypes.string
    }),
    handleManualRecording: PropTypes.func,
    isAdmin: PropTypes.bool,
    callTools: PropTypes.shape({
        chat: PropTypes.bool,
        screen_sharing: PropTypes.bool,
        file_upload: PropTypes.bool,
        snapshot: PropTypes.bool,
        live_edit: PropTypes.bool,
        live_pointer: PropTypes.bool,
        whiteboard: PropTypes.any
    }),
    showRequestPlayPermission: PropTypes.bool,
    failedMediaElements: PropTypes.instanceOf(Array),
    setRequestPlayPermission: PropTypes.func
};

CallComponent.defaultProps = {
    call: Map({}),
    publishStream: null,
    audioMuted: false,
    videoMuted: false,
    toggleAudio: null,
    toggleVideo: null,
    canVideo: true,
    disconnectCall: null,
    webcamPublished: false,
    screenPublished: false,
    publishScreen: null,
    unpublishScreen: null,
    toggleFullScreen: null,
    fullScreenMode: false,
    extensionUrl: '',
    closeAlert: null,
    showExtensionAlert: false,
    headerStyle: Map({}),
    callStyle: Map({}),
    localUser: Map({}),
    changeView: null,
    videoHasAudioTrack: false,
    videoHasVideoTrack: false,
    addChannel: null,
    selectChannel: null,
    record: false,
    channels: List([]),
    notification: 0,
    currentView: null,
    getMediaDevices: null,
    mediaDevices: [],
    selectedAudioDevice: 'none',
    selectedVideoDevice: 'none',
    selectAudioDevice: null,
    selectVideoDevice: null,
    changeDeviceSource: null,
    cameraPermissionDenied: false,
    microphonePermissionDenied: false,
    setCameraPermissionDenied: null,
    setMicrophonePermissionDenied: null,
    mediaStream: null,
    streamEnded: false,
    streamFailed: false,
    setStreamEndedError: null,
    setStreamFailedError: null,
    reconnectingCall: false,
    error: null,
    verification: null,
    usersDetails: Map({}),
    remoteMuted: false,
    mainVideo: null,
    filesToDownload: Map([]),
    filesUploaded: Map([]),
    addFileUploaded: null,
    updateDownloadFile: null,
    removeFileUploaded: null,
    recordingInfo: { recording: false, recordingType: 'automatic' },
    handleManualRecording: null,
    isAdmin: false,
    callTools: PropTypes.shape({
        chat: true,
        screen_sharing: true,
        file_upload: true,
        snapshot: true,
        live_edit: true,
        live_pointer: true,
        whiteboard: PropTypes.shape({
            wb_add_file: true,
            wb_cursor: true,
            wb_text: true,
            wb_shape: true,
            wb_pen: true,
            wb_eraser: true
        })
    }),
    showRequestPlayPermission: null,
    failedMediaElements: [],
    setRequestPlayPermission: null
};

export default injectIntl(CallComponent);
