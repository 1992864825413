import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import * as actions from './actions/actionTypes';
import { detectIE } from '../helpers/utils';
import Logger from '../logger';


import activity from './reducers/activityReducer';
import behavior from './reducers/behaviorReducer';
import call from './reducers/callReducer';
import channels from './reducers/channelsReducer';
import errors from './reducers/errorReducer';
import messages from './reducers/messagesReducer';
import styles from './reducers/styleReducer';
import socketState from './reducers/socketStateReducer';
import usersDetails from './reducers/usersDetailsReducers';

const appReducer = combineReducers({
    activity,
    behavior,
    call,
    channels,
    errors,
    messages,
    styles,
    socketState,
    usersDetails
});
const rootReducer = (state, action) => {
    if (action.type === actions.LOGOUT) {
        state = undefined;
    }
    return appReducer(state, action);
};
const middleware = [thunk];
Logger.setLevel('error');
if (process.env.NODE_ENV === 'development' && !detectIE()) {
    Logger.setLevel('trace');
    const logger = createLogger({ collapsed: true });
    middleware.push(...middleware, logger);
}

export default createStore(rootReducer, applyMiddleware(...middleware));
